.footer-counter {
  position: relative;
  @include breakpoints-up(md) {
    margin-top: $spacing10;
  }
  .deco {
    position: absolute;
    top: -225px;
    left: 0;
    width: 100%;
    opacity: 0.5;
    direction: ltr;
    img {
      @include breakpoints-down(xs) {
        display: none
      }
    }
    .deco-main {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
    .container {
      position: relative;
    }
    figure {
      height: 130px;
      width: 340px;
      top: 94px;
      overflow: hidden;
      position: absolute;
      img {
        width: 100%;
      }
    }
  }

  .deco-left {
    width: 225px;
    height: 225px;
    @include use-theme(background, $palette-accent-light, $palette-accent-dark);
    @include pie2;
  }

  .deco-right {
    width: 173px;
    height: 173px;
    @include use-theme(background, $palette-primary-light, $palette-primary-dark);
    @include pie1;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: -100px;
      bottom: 0;
      width: 100px;
      height: 100px;
      @include pie2;
      @include use-theme(background, $palette-accent-light, $palette-accent-dark);
    }
  }

  .deco-footer {
    width: 340px;
    height: 100%;
    position: absolute;
    @include right(0);
    @include rtl-flip;
    bottom: 0;
    @include use-theme(background, $light-background-paper, $dark-background-default);
    @include breakpoints-down(sm) {
      display: none;
    }
  }

  .deco-top {
    width: 100%;
    height: 100%;
    @include pie3;
    @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    &:after {
      content: "";
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include use-theme(background, $palette-common-white, $palette-common-black);
    }
  }

  .deco-bottom {
    width: 100%;
    height: 100%;
    @include pie2;
    @include use-theme(background, $palette-primary-light, $palette-primary-dark);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    &:after {
      content: "";
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include use-theme(background, $light-background-paper, $dark-background-default);
    }
  }

  .counter {
    padding-bottom: $spacing10;
    &:before {
      content: "";
      position: absolute;
      width: 1100px;
      height: 1100px;
      border-radius: 50%;
      left: 50%;
      opacity: .5;
      transform: translateX(-50%);
      @include use-theme(background, $palette-primary-light, $palette-primary-dark);
      top: -400px;
      @include breakpoints-down(xs) {
        top: -280px;
      }
    }
  }

  .action {
    z-index: 1;
    text-align: center;
    padding: spacing(0, 2);
    position: relative;
    h4 {
      font-weight: $font-bold;
      margin-bottom: $spacing3;
      @include breakpoints-down(xs) {
        font-size: 28px;
        line-height: 44px
      }
    }
    .btn {
      width: 200px;
    }
  }

  .footer-container {
    position: relative;
    @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include use-theme(background, $palette-common-white, $palette-common-black);
      opacity: 0.5;
    }
  }
}

