.faq-style {
  .root {
    position: relative;
   .container {
      position: relative;
      z-index: 3
    }
  }
  & > .faq-group {
    position: relative;
    margin-bottom: $spacing10;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text {
    position: relative;
    z-index: 2
  }
  .illustration {
    position: relative;
    width: 500px;
    margin: 40px auto;
    position: relative;
    img {
      display: block;
      width: 100%;
      position: relative;
    }
  }
  .accordion {
    position: relative;
  }
  .item {
    margin-bottom: $spacing3
  }
  .paper {
    border-radius: $rounded-medium !important;
    margin-bottom: $spacing3;
    overflow: hidden;
    .theme--dark & {
      box-shadow: 0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)
    }
    .theme--light & {
      box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06)
    }
  }
  .heading {
    font-weight: $font-medium;
    padding: spacing(1, 2, 1, 0);
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0;
    
    flex: 1;
    @include breakpoints-down(xs) {
      font-size: 16px;
    }
  }
  .content {
    .icon {
      position: absolute;
      top: 20px;
      @include right($spacing2)
    }
  }
  .active {
    .collapsible-header {
      background: $palette-secondary-main;
      color: $palette-common-white;
    }
    i.material-icons {
      color: $palette-common-white;
      transform: rotate(180deg)
    }
  }
  .detail {
    @include palette-background-paper;
    padding-top: $spacing3;
    border-radius: 0 0 $rounded-medium $rounded-medium;
    p {
      font-size: 18px;
      @include breakpoints-down(xs) {
        font-size: 16px;
      }
    }
  }
  .icon {
    @include use-theme(color, $palette-secondary-main, $palette-secondary-light);
  }
  .topic-list {
    padding: 0;
    li {
      padding: spacing(2, 0);
      border-bottom: 1px solid;
      @include palette-divider;
      list-style: none;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline
        }
      }
    }
  }
}
