.banner-slider {
  .root {
    position: relative;
    overflow: hidden;
  }
  .banner-wrap {
    .slick-dots {
      bottom: 0
    }
  }
  .slider {
    z-index: 1;
    height: auto;
  }
  .slide {
    direction: ltr;
    * {
      direction: ltr;
    }
    @include breakpoints-down(sm) {
      text-align: center;
      padding: spacing(15, 0, 0, 0);
    }
    img {
      max-height: 350px;
      position: relative;
      @include breakpoints-up(md) {
        max-width: 100%;
      }
      @include breakpoints-down(md) {
        margin: 0 auto;
        margin-top: $spacing3;
      }
    }
    &.center-content {
      @include breakpoints-up(md) {
        padding-top: $spacing20 !important;
      }
    }
  }
  .img-slide1 {
    @include breakpoints-between(md, lg) {
      float: left;
      right: -40px;
    }
  }

  .img-slide2 {
    @include breakpoints-up(md) {
      margin-right: 0 !important;
    }
    @include breakpoints-between(md, xl) {
      float: right
    }
  }

  .h-banner {
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
      margin-top: $spacing2;
      @include breakpoints-up(md) {
        width: 50%;
      }
      @include breakpoints-down(xs){
        margin-left: -100px
      }
    }
  }

  .background-banner {
    position: absolute;
    right: -260px;
    padding-top: 20px;
    img {
      max-height: 320px;
    }
    @include breakpoints-up(xl) {
      display: none;
    }
    @include breakpoints-down(sm) {
      display: none
    }
    @include breakpoints-down(xs) {
      display: none;
    }
  }

  .carousel {
    .slide {
      @include breakpoints-up('md') {
        height: 600px;
        padding-top: $spacing8;
        padding-bottom: $spacing5;
      }
    }
  }
  .inner {
    display: flex;
    height: 100%;
    position: relative;
    align-items: center;
    @include breakpoints-down('md') {
      flex-direction: column;
      justify-content: center;
    }
  }
  .text {
    h4 {
      font-weight: $font-bold;
      margin-bottom: $spacing2;
    }
    h5 {
      @include palette-text-secondary;
      margin-bottom: $spacing4;
      font-weight: $font-medium;
    }
  }
  .title {
    position: relative;
    h4 {
      position: relative;
    }
  }
  .title-wrap {
    display: inline-block;
    margin: 0 auto;
  }
  .btn-area {
    margin-top: $spacing5;
    position: relative;
    z-index: 2;
    @include breakpoints-down(sm) {
      text-align: center
    }
    .btn {
      @include margin-right($spacing2);
      @include breakpoints-down(xs) {
        margin-bottom: $spacing3;
        height: 50px;
      }
    }
  }
  .slide-nav-group {
    position: relative;
    z-index: 2
  }
  .slide-nav {
    display: flex;
    position: relative;
    z-index: 5;
    max-width: 1080px;
    margin: 0 auto;
    height: auto;
    a {
      line-height: normal;
      text-transform: none;
      height: auto;
      flex: 1;
      border: 1px solid transparent;
      font-weight: $font-regular;
      position: relative;
      transition: all 0.3s ease;
      border-radius: $rounded-medium;
      padding: spacing(2, 1.5);
      font-size: 14px;
      strong {
        text-transform: capitalize;
        font-size: 28px;
        display: block;
        font-weight: $font-bold;
        @include margin-left(-2px);
        z-index: 1;
      }
      &:hover {
        border-color: $palette-secondary-main;
        @include use-theme(background, rgba(0, 0, 0, 0.08), rgba(255, 255, 255, 0.08))
      }
      &.active {
        border-color: $palette-primary-main;
        @include use-theme(color, $palette-primary-dark, $palette-primary-light);
        strong {
          @include use-theme(color, $palette-secondary-dark, $palette-secondary-light);
        }
      }
    }
  }

  .divider {
    margin: 0 $spacing2;
    @include palette-divider;
    min-height: 0;
    max-height: calc(100% - 16px);
    align-self: stretch;
    border: solid;
    border-width: 0 thin 0 0;
    display: inline-flex;
    height: inherit;
    max-width: 0;
    width: 0;
    vertical-align: text-bottom;
    flex: 1 1 0px;
  }

  .deco {
    position: absolute;
    top: 0;
    left: -180px;
    display: flex;
    width: 828px;
    height: 324px;
    @include use-theme(opacity, 0.5, 0.2);
    & > div {
      position: relative;
      flex: 1;
    }
    &-left {
      .leaf {
        left: -60px;
        @include pie4;
        @include use-theme(background, $palette-accent-light, $palette-accent-dark);
      }
      .round {
        @include use-theme(background, $palette-primary-light, $palette-primary-dark);
      }
      .land {
        right: 0;
        @include pie1;
        @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
      }
    }
    &-right {
      .leaf {
        right: -60px;
        @include pie3;
        @include use-theme(background, $palette-accent-light, $palette-accent-dark);
      }
      .round {
        @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
      }
      .land {
        left: 0;
        @include pie2;
        @include use-theme(background, $palette-primary-light, $palette-primary-dark);
      }
    }
  }

  .round {
    width: 176px;
    height: 176px;
    position: absolute;
    top: -10px;
    left: 30%;
    border-radius: 50%
  }

  .leaf {
    width: 192px;
    height: 192px;
    position: absolute;
    top: 80px;
  }

  .land {
    width: 350px;
    height: 350px;
    position: absolute;
    bottom: 0;
  }
}
