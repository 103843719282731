/** spacing funcation
** Sample 1: margin-left: spacing(5);
** Sample 2: @include margin-left(spacing(5));
**/

@function spacing($top, $right: -1, $bottom: -1, $left: -1) {
  @if ($left > -1) {
     @return ($top * $unit) ($right * $unit) ($bottom * $unit) ($left * $unit);
  }
  
  @if ($bottom > -1) {
     @return ($top * $unit) ($right * $unit) ($bottom * $unit) ($right * $unit);
  }
  
  @if ($right > -1) {
     @return ($top * $unit) ($right * $unit) ($top * $unit) ($right * $unit);
  }
  
  @return ($top * $unit)
}
