/*
** theme color var
*/
@import './color-var';

html {
  @media only screen and (min-width: 992px) {
    font-size: 16px
  }
  @media only screen and (min-width: 0) {
    font-size: 16px
  }
}

a {
  color: $palette-primary-main;
}

.m-application {
  overflow: hidden;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  figure {
    margin: 0;
  }
  .btn{
    &:not(.btn-flat):not(.btn-icon):not(.btn-outlined) {
      &:hover {
        color: #FFF;
      }
      &:focus {
        box-shadow: none
      }
      &:active {
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      }
    }
    &-icon {
      &:focus {
        background-color: transparent
      }
    }
  }
  nav {
    box-shadow: none;
    background: none;
    width: auto;
    color: inherit;
    i {
      height: auto;
      line-height: normal;
    }
    ul a:hover {
      background: none
    }
    .sidenav-trigger {
      height: 36px;
    }
  }
  &--is-rtl {
    text-align: right
  }
  .m-content {
    font-family: 'Montserrat', sans-serif;
    padding: 0 !important;
    button, input, optgroup, select, textarea {
      font-family: 'Montserrat', sans-serif;
    }
  }
  .m-application--wrap a {
    color: $palette-primary-main
  }
  .btn.btn-large {
    font-size: 0.9375rem;
  }
  .btn {
    font-weight: 600;
  }
  label {
    font-size: 1rem;
    color: var(--con-2);
  }
  .row {
    flex-direction: row;
    &.spacing2 {
      margin-left: -8px;
      margin-right: -8px;
    }
    &.spacing4 {
      margin-left: -16px;
      margin-right: -16px;
    }
    &.spacing6 {
      margin-left: -24px;
      margin-right: -24px;
    }
    &.spacing8 {
      margin-left: -32px;
      margin-right: -32px;
    }
    &.row-reverse {
      flex-direction: row-reverse
    }
    &.column {
      flex-direction: column
    }
    &.column-reverse {
      flex-direction: column-reverse
    }
  }
  :focus {
    outline: none;
  }
  .card {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    &-content {
      font-size: 0.875rem;
    }
  }
  .carousel {
    height: auto;
    overflow: visible;
  }
  .modal {
    border-radius: 12px;
    max-height: 480px;
    @include breakpoints-down(xs) {
      width: 98%
    }
  }
  .overlay {
    z-index: 90 !important
  }
  .slick-slide {
    &:focus {
      outline: none;
    }
  }
  .slick-dots {
    padding-left: 0;
    bottom: -40px;
    position: relative;
    li {
      margin: 0 3px;
      button:before {
        border-radius: 50%;
        color: transparent !important;
        width: 10px;
        height: 10px;
        border: 2px solid $palette-primary-main;
        opacity: 0.5;
        transition: all 0.3s ease;
      }
      &.slick-active {
        button:before {
          width: 20px;
          height: 20px;
          opacity: 1;
          top: -5px;
          left: -5px;
          background: $palette-primary-main !important;
        }
      }
    }
  }
  .accordion-content.active {
    .collapsible-header > .arrow {
      transform: rotate(180deg);
    }
  }
  .collapsible-header {
    > i {
      line-height: 32px;
      transition: all 0.3s ease;
      transform-origin: center;
      height: 28px;
      width: 28px;
    }
    h6 {
      margin: 0;
      flex: 1;
    }
  }
  .collapsible-body {
    padding: 0 1rem;
  }
}

.m-application.theme--dark {
  .card {
    box-shadow: 0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)
  }
}

.m-application.theme--light {
  .card {
    box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
  }
}

.m-content {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}

/* Content Overider */
#main-wrap .container {
  padding: 0 16px;
  max-width: 1280px;
  width: 100%;
  @media (min-width: 600px) {
    padding: 0 24px
  }
  @media (min-width: 960px) {
    padding: 0 32px
  }
  &.fixed-width {
    @media (min-width: 600px) {
      max-width: 600px;
    }
    @media (min-width: 960px) {
      max-width: 960px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
  }
  &.max-lg {
    max-width: 1280px
  }
  &.max-md {
    max-width: 960px
  }
  &.max-sm {
    max-width: 600px
  }
  &.max-xs {
    max-width: 444px
  }
}

.card .card-action a:not(.btn):not(.btn-small):not(.btn-large):not(.btn-large):not(.btn-floating) {
  color: $palette-primary-main !important;
  padding: 0;
}

select.select {
  display: none;
}

.dropdown-content li > a, .dropdown-content li > span {
  @include palette-text-primary
}

/* Theme overider */
// Preloader
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  &-inactive {
    display: none;
  }
  .pace-progress {
    background: #4CAF50;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
  }
}

/* Theme overider */
.money {
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 600;
  .btn {
    border-radius: 6px;
    font-weight: $font-bold;
    &.primary {
      box-shadow: 0 0 4px -1px $palette-primary-main
    }
    &.secondary {
      box-shadow: 0 0 4px -1px $palette-secondary-main
    }
  }
  .btn-flat {
    font-weight: $font-bold;
  }
  .input-field.filled {
    > label {
      @include left($spacing1);
      top: 6px;
      &:not(.label-icon).active {
        transform: translateY(1px) scale(0.8) !important
      }
    }
    input, textarea {
      border-radius: 6px 6px 0 0;
      padding: 25px 8px 4px;
      box-sizing: border-box;
      transition: all 0.3s ease;
      @include use-theme(background-color, rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0.08));
      &:hover {
        @include use-theme(background-color, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
      }
      &:focus, &:active {
        @include use-theme(background-color, rgba(0, 0, 0, 0.06), rgba(255, 255, 255, 0.08));
      }
    }
    input {
      height: 3.5rem;
    }
  }
  .tabs {
    background: transparent;
    @include breakpoints-up(sm) {
      display: flex;
      justify-content: center;
    }
    a {
      @include palette-text-secondary;
      &:focus {
        @include use-theme(color, $palette-secondary-main, $palette-secondary-light);
        @include use-theme(background-color, $palette-secondary-light, $light-divider)
      }
      &.active {
        color: $palette-secondary-main
      }
    }
    .indicator {
      height: 5px;
      border-radius: 4px 4px 0 0;
      bottom: 0px;
      background: $palette-secondary-light;
    }
  }
}