.logo-main {
  font-weight: $font-bold;
  @include palette-text-primary;
  display: flex;
  &.landscape {
    align-items: center;
    position: relative;
    top: -2px;
    img {
      @include margin-right($spacing2)
    }
  }
  &.portrait {
    display: block;
    margin: 0 auto 8px;
    img {
      margin: 0 auto $spacing1;
      display: block;
    }
  }
  &.small {
    font-size: 16px;
    img {
      max-width: 34px;
      max-height: 34px;
    }
  }
  &.medium {
    font-size: 18px;
    img {
      max-width: 54px;
      @include breakpoints-down(xs) {
        max-Width: 32px;
        max-height: 32px;
      }
    }
  }
  &.large {
    font-size: 26px;
    img {
      max-width: 64px;
      max-height: 64px;
    }
  }
}
