.footer-blog {
  .link {
    margin: spacing(1, 1.5);
  }
  .footer {
    position: relative;
    padding: spacing(7, 0);
    .container {
      position: relative;
    }
    @include breakpoints-down(sm) {
      padding-bottom: $spacing7
    }
    p {
      @include breakpoints-down(xs) {
        text-align: center
      }
    }
    &-desc{
      display: block;
      font-size: 14px;
      margin-bottom: $spacing2
    }
  }
  .quick-links {
    @include breakpoints-down(sm) {
      margin-top: $spacing5;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: $spacing1;
        display: inline-block;
        width: 30%;
        @include margin-right(2%);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoints-down(xs) {
          width: 47%
        }
        a {
          text-transform: capitalize;
          text-decoration: none !important;
          font-size: 14px;
          @include palette-text-primary;
          font-weight: $font-medium;
          &:hover {
            color: $palette-primary-main
          }
        }
      }
    }
  }

  .blog-item {
    display: flex;
    align-items: center;
    height: auto !important;
    white-space: normal;
    @include text-align(left);
    justify-content: flex-start;
    padding: $spacing1;
    .category {
      margin-bottom: $spacing1;
      display: block;
      line-height: normal;
      font-weight: $font-bold;
      text-transform: uppercase;
      @include use-theme(color, $palette-primary-dark, $palette-primary-light)
    }
    .list-text {
      flex: 1;
      display: block;
    }
    .figure {
      border-radius: 4px;
      overflow: hidden;
      @include margin-right($spacing2);
      width: 80px;
      height: 56px;
      img {
        display: block;
        min-height: 100%;
        width: 100%;
      }
    }
    .content {
      font-size: 14px;
      line-height: 21px;
      text-transform: none;
      margin-bottom: 0;
      display: block;
      font-weight: $font-regular;
      @include text-align(left);
      @include breakpoints-down(xs) {
        font-size: 12px;
        line-height: 16px
      }
    }
  }

  .title-nav {
    @include use-theme(color, $palette-primary-dark, $palette-primary-light);
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: $spacing1;
    font-weight: $font-bold
  }
  .logo {
    display: flex;
    align-items: flex-end;
    margin-bottom: $spacing3;
    align-items: center;
    @include breakpoints-down(sm) {
      justify-content: center;
      & + p {
        text-align: center;
      }
    }
    img {
      width: 54px;
      height: 54px;
      @include margin-right($spacing2)
    }
    h6 {
      font-weight: $font-medium;
      font-size: 26px;
      text-transform: capitalize;
    }
  }
  .socmed {
    display: flex;
    margin-bottom: $spacing4;
    @include breakpoints-down(sm) {
      justify-content: center;
    }
    a.btn-icon {
      border-radius: 50%;
      margin: $spacing1;
      padding: 0;
      @include use-theme(background, $light-divider, $dark-divider);
      .icon {
        @include use-theme(color, $palette-primary-dark, $palette-primary-light);
      }
    }
    .icon {
      font-size: 24px
    }
  }

  .icon {
    & + div {
      background: none !important;
      padding: spacing(1.5, 1.5, 1.5, 4);
      width: calc(100% - 32px)
    }
  }

  .select-lang {
    display: inherit;
    margin: spacing(2, 1);
    max-width: 200px;
    @include breakpoints-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }
    input {
      box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
      border-color: $palette-primary-main;
      height: 2.8rem;
    }
  }

  .site-map-item {
    @include breakpoints-down('md') {
      padding-bottom: 0 !important;
      padding-top: 0 !important
    }
  }
  .copyright {
    @include breakpoints-down(sm) {
      text-align: center;
    }
  }
}