.page-fadeUp-transition-enter {
  opacity: 0;
}

.page-fadeUp-transition-enter-active {
  opacity: 1;
  -webkit-transition: opacity 400ms, -webkit-transform 400ms;
  -webkit-transition: opacity 400ms, transform 400ms;
  transition: opacity 400ms, transform 400ms;
}

.page-fadeUp-transition-exit {
  opacity: 1;
}