
.m-application .ma-0 {
  margin: 0px !important;
}

.m-application .ma-1 {
  margin: 4px !important;
}

.m-application .ma-2 {
  margin: 8px !important;
}

.m-application .ma-3 {
  margin: 12px !important;
}

.m-application .ma-4 {
  margin: 16px !important;
}

.m-application .ma-5 {
  margin: 20px !important;
}

.m-application .ma-6 {
  margin: 24px !important;
}

.m-application .ma-7 {
  margin: 28px !important;
}

.m-application .ma-8 {
  margin: 32px !important;
}

.m-application .ma-9 {
  margin: 36px !important;
}

.m-application .ma-10 {
  margin: 40px !important;
}

.m-application .ma-11 {
  margin: 44px !important;
}

.m-application .ma-12 {
  margin: 48px !important;
}

.m-application .ma-auto {
  margin: auto !important;
}

.m-application .mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.m-application .mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.m-application .mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.m-application .mx-3 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.m-application .mx-4 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.m-application .mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.m-application .mx-6 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.m-application .mx-7 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.m-application .mx-8 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.m-application .mx-9 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.m-application .mx-10 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.m-application .mx-11 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.m-application .mx-12 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.m-application .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-application .my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m-application .my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-application .my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m-application .my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m-application .my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-application .my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-application .my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m-application .my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.m-application .my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m-application .my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.m-application .my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-application .my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.m-application .my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m-application .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-application .mt-0 {
  margin-top: 0px !important;
}

.m-application .mt-1 {
  margin-top: 4px !important;
}

.m-application .mt-2 {
  margin-top: 8px !important;
}

.m-application .mt-3 {
  margin-top: 12px !important;
}

.m-application .mt-4 {
  margin-top: 16px !important;
}

.m-application .mt-5 {
  margin-top: 20px !important;
}

.m-application .mt-6 {
  margin-top: 24px !important;
}

.m-application .mt-7 {
  margin-top: 28px !important;
}

.m-application .mt-8 {
  margin-top: 32px !important;
}

.m-application .mt-9 {
  margin-top: 36px !important;
}

.m-application .mt-10 {
  margin-top: 40px !important;
}

.m-application .mt-11 {
  margin-top: 44px !important;
}

.m-application .mt-12 {
  margin-top: 48px !important;
}

.m-application .mt-auto {
  margin-top: auto !important;
}

.m-application .mr-0 {
  margin-right: 0px !important;
}

.m-application .mr-1 {
  margin-right: 4px !important;
}

.m-application .mr-2 {
  margin-right: 8px !important;
}

.m-application .mr-3 {
  margin-right: 12px !important;
}

.m-application .mr-4 {
  margin-right: 16px !important;
}

.m-application .mr-5 {
  margin-right: 20px !important;
}

.m-application .mr-6 {
  margin-right: 24px !important;
}

.m-application .mr-7 {
  margin-right: 28px !important;
}

.m-application .mr-8 {
  margin-right: 32px !important;
}

.m-application .mr-9 {
  margin-right: 36px !important;
}

.m-application .mr-10 {
  margin-right: 40px !important;
}

.m-application .mr-11 {
  margin-right: 44px !important;
}

.m-application .mr-12 {
  margin-right: 48px !important;
}

.m-application .mr-auto {
  margin-right: auto !important;
}

.m-application .mb-0 {
  margin-bottom: 0px !important;
}

.m-application .mb-1 {
  margin-bottom: 4px !important;
}

.m-application .mb-2 {
  margin-bottom: 8px !important;
}

.m-application .mb-3 {
  margin-bottom: 12px !important;
}

.m-application .mb-4 {
  margin-bottom: 16px !important;
}

.m-application .mb-5 {
  margin-bottom: 20px !important;
}

.m-application .mb-6 {
  margin-bottom: 24px !important;
}

.m-application .mb-7 {
  margin-bottom: 28px !important;
}

.m-application .mb-8 {
  margin-bottom: 32px !important;
}

.m-application .mb-9 {
  margin-bottom: 36px !important;
}

.m-application .mb-10 {
  margin-bottom: 40px !important;
}

.m-application .mb-11 {
  margin-bottom: 44px !important;
}

.m-application .mb-12 {
  margin-bottom: 48px !important;
}

.m-application .mb-auto {
  margin-bottom: auto !important;
}

.m-application .ml-0 {
  margin-left: 0px !important;
}

.m-application .ml-1 {
  margin-left: 4px !important;
}

.m-application .ml-2 {
  margin-left: 8px !important;
}

.m-application .ml-3 {
  margin-left: 12px !important;
}

.m-application .ml-4 {
  margin-left: 16px !important;
}

.m-application .ml-5 {
  margin-left: 20px !important;
}

.m-application .ml-6 {
  margin-left: 24px !important;
}

.m-application .ml-7 {
  margin-left: 28px !important;
}

.m-application .ml-8 {
  margin-left: 32px !important;
}

.m-application .ml-9 {
  margin-left: 36px !important;
}

.m-application .ml-10 {
  margin-left: 40px !important;
}

.m-application .ml-11 {
  margin-left: 44px !important;
}

.m-application .ml-12 {
  margin-left: 48px !important;
}

.m-application .ml-auto {
  margin-left: auto !important;
}

[dir="ltr"] .m-application .ms-0 {
  margin-left: 0px !important;
}

[dir="rtl"] .m-application .ms-0 {
  margin-right: 0px !important;
}

[dir="ltr"] .m-application .ms-1 {
  margin-left: 4px !important;
}

[dir="rtl"] .m-application .ms-1 {
  margin-right: 4px !important;
}

[dir="ltr"] .m-application .ms-2 {
  margin-left: 8px !important;
}

[dir="rtl"] .m-application .ms-2 {
  margin-right: 8px !important;
}

[dir="ltr"] .m-application .ms-3 {
  margin-left: 12px !important;
}

[dir="rtl"] .m-application .ms-3 {
  margin-right: 12px !important;
}

[dir="ltr"] .m-application .ms-4 {
  margin-left: 16px !important;
}

[dir="rtl"] .m-application .ms-4 {
  margin-right: 16px !important;
}

[dir="ltr"] .m-application .ms-5 {
  margin-left: 20px !important;
}

[dir="rtl"] .m-application .ms-5 {
  margin-right: 20px !important;
}

[dir="ltr"] .m-application .ms-6 {
  margin-left: 24px !important;
}

[dir="rtl"] .m-application .ms-6 {
  margin-right: 24px !important;
}

[dir="ltr"] .m-application .ms-7 {
  margin-left: 28px !important;
}

[dir="rtl"] .m-application .ms-7 {
  margin-right: 28px !important;
}

[dir="ltr"] .m-application .ms-8 {
  margin-left: 32px !important;
}

[dir="rtl"] .m-application .ms-8 {
  margin-right: 32px !important;
}

[dir="ltr"] .m-application .ms-9 {
  margin-left: 36px !important;
}

[dir="rtl"] .m-application .ms-9 {
  margin-right: 36px !important;
}

[dir="ltr"] .m-application .ms-10 {
  margin-left: 40px !important;
}

[dir="rtl"] .m-application .ms-10 {
  margin-right: 40px !important;
}

[dir="ltr"] .m-application .ms-11 {
  margin-left: 44px !important;
}

[dir="rtl"] .m-application .ms-11 {
  margin-right: 44px !important;
}

[dir="ltr"] .m-application .ms-12 {
  margin-left: 48px !important;
}

[dir="rtl"] .m-application .ms-12 {
  margin-right: 48px !important;
}

[dir="ltr"] .m-application .ms-auto {
  margin-left: auto !important;
}

[dir="rtl"] .m-application .ms-auto {
  margin-right: auto !important;
}

[dir="ltr"] .m-application .me-0 {
  margin-right: 0px !important;
}

[dir="rtl"] .m-application .me-0 {
  margin-left: 0px !important;
}

[dir="ltr"] .m-application .me-1 {
  margin-right: 4px !important;
}

[dir="rtl"] .m-application .me-1 {
  margin-left: 4px !important;
}

[dir="ltr"] .m-application .me-2 {
  margin-right: 8px !important;
}

[dir="rtl"] .m-application .me-2 {
  margin-left: 8px !important;
}

[dir="ltr"] .m-application .me-3 {
  margin-right: 12px !important;
}

[dir="rtl"] .m-application .me-3 {
  margin-left: 12px !important;
}

[dir="ltr"] .m-application .me-4 {
  margin-right: 16px !important;
}

[dir="rtl"] .m-application .me-4 {
  margin-left: 16px !important;
}

[dir="ltr"] .m-application .me-5 {
  margin-right: 20px !important;
}

[dir="rtl"] .m-application .me-5 {
  margin-left: 20px !important;
}

[dir="ltr"] .m-application .me-6 {
  margin-right: 24px !important;
}

[dir="rtl"] .m-application .me-6 {
  margin-left: 24px !important;
}

[dir="ltr"] .m-application .me-7 {
  margin-right: 28px !important;
}

[dir="rtl"] .m-application .me-7 {
  margin-left: 28px !important;
}

[dir="ltr"] .m-application .me-8 {
  margin-right: 32px !important;
}

[dir="rtl"] .m-application .me-8 {
  margin-left: 32px !important;
}

[dir="ltr"] .m-application .me-9 {
  margin-right: 36px !important;
}

[dir="rtl"] .m-application .me-9 {
  margin-left: 36px !important;
}

[dir="ltr"] .m-application .me-10 {
  margin-right: 40px !important;
}

[dir="rtl"] .m-application .me-10 {
  margin-left: 40px !important;
}

[dir="ltr"] .m-application .me-11 {
  margin-right: 44px !important;
}

[dir="rtl"] .m-application .me-11 {
  margin-left: 44px !important;
}

[dir="ltr"] .m-application .me-12 {
  margin-right: 48px !important;
}

[dir="rtl"] .m-application .me-12 {
  margin-left: 48px !important;
}

[dir="ltr"] .m-application .me-auto {
  margin-right: auto !important;
}

[dir="rtl"] .m-application .me-auto {
  margin-left: auto !important;
}

.m-application .ma-n1 {
  margin: -4px !important;
}

.m-application .ma-n2 {
  margin: -8px !important;
}

.m-application .ma-n3 {
  margin: -12px !important;
}

.m-application .ma-n4 {
  margin: -16px !important;
}

.m-application .ma-n5 {
  margin: -20px !important;
}

.m-application .ma-n6 {
  margin: -24px !important;
}

.m-application .ma-n7 {
  margin: -28px !important;
}

.m-application .ma-n8 {
  margin: -32px !important;
}

.m-application .ma-n9 {
  margin: -36px !important;
}

.m-application .ma-n10 {
  margin: -40px !important;
}

.m-application .ma-n11 {
  margin: -44px !important;
}

.m-application .ma-n12 {
  margin: -48px !important;
}

.m-application .mx-n1 {
  margin-right: -4px !important;
  margin-left: -4px !important;
}

.m-application .mx-n2 {
  margin-right: -8px !important;
  margin-left: -8px !important;
}

.m-application .mx-n3 {
  margin-right: -12px !important;
  margin-left: -12px !important;
}

.m-application .mx-n4 {
  margin-right: -16px !important;
  margin-left: -16px !important;
}

.m-application .mx-n5 {
  margin-right: -20px !important;
  margin-left: -20px !important;
}

.m-application .mx-n6 {
  margin-right: -24px !important;
  margin-left: -24px !important;
}

.m-application .mx-n7 {
  margin-right: -28px !important;
  margin-left: -28px !important;
}

.m-application .mx-n8 {
  margin-right: -32px !important;
  margin-left: -32px !important;
}

.m-application .mx-n9 {
  margin-right: -36px !important;
  margin-left: -36px !important;
}

.m-application .mx-n10 {
  margin-right: -40px !important;
  margin-left: -40px !important;
}

.m-application .mx-n11 {
  margin-right: -44px !important;
  margin-left: -44px !important;
}

.m-application .mx-n12 {
  margin-right: -48px !important;
  margin-left: -48px !important;
}

.m-application .my-n1 {
  margin-top: -4px !important;
  margin-bottom: -4px !important;
}

.m-application .my-n2 {
  margin-top: -8px !important;
  margin-bottom: -8px !important;
}

.m-application .my-n3 {
  margin-top: -12px !important;
  margin-bottom: -12px !important;
}

.m-application .my-n4 {
  margin-top: -16px !important;
  margin-bottom: -16px !important;
}

.m-application .my-n5 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.m-application .my-n6 {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
}

.m-application .my-n7 {
  margin-top: -28px !important;
  margin-bottom: -28px !important;
}

.m-application .my-n8 {
  margin-top: -32px !important;
  margin-bottom: -32px !important;
}

.m-application .my-n9 {
  margin-top: -36px !important;
  margin-bottom: -36px !important;
}

.m-application .my-n10 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.m-application .my-n11 {
  margin-top: -44px !important;
  margin-bottom: -44px !important;
}

.m-application .my-n12 {
  margin-top: -48px !important;
  margin-bottom: -48px !important;
}

.m-application .mt-n1 {
  margin-top: -4px !important;
}

.m-application .mt-n2 {
  margin-top: -8px !important;
}

.m-application .mt-n3 {
  margin-top: -12px !important;
}

.m-application .mt-n4 {
  margin-top: -16px !important;
}

.m-application .mt-n5 {
  margin-top: -20px !important;
}

.m-application .mt-n6 {
  margin-top: -24px !important;
}

.m-application .mt-n7 {
  margin-top: -28px !important;
}

.m-application .mt-n8 {
  margin-top: -32px !important;
}

.m-application .mt-n9 {
  margin-top: -36px !important;
}

.m-application .mt-n10 {
  margin-top: -40px !important;
}

.m-application .mt-n11 {
  margin-top: -44px !important;
}

.m-application .mt-n12 {
  margin-top: -48px !important;
}

.m-application .mr-n1 {
  margin-right: -4px !important;
}

.m-application .mr-n2 {
  margin-right: -8px !important;
}

.m-application .mr-n3 {
  margin-right: -12px !important;
}

.m-application .mr-n4 {
  margin-right: -16px !important;
}

.m-application .mr-n5 {
  margin-right: -20px !important;
}

.m-application .mr-n6 {
  margin-right: -24px !important;
}

.m-application .mr-n7 {
  margin-right: -28px !important;
}

.m-application .mr-n8 {
  margin-right: -32px !important;
}

.m-application .mr-n9 {
  margin-right: -36px !important;
}

.m-application .mr-n10 {
  margin-right: -40px !important;
}

.m-application .mr-n11 {
  margin-right: -44px !important;
}

.m-application .mr-n12 {
  margin-right: -48px !important;
}

.m-application .mb-n1 {
  margin-bottom: -4px !important;
}

.m-application .mb-n2 {
  margin-bottom: -8px !important;
}

.m-application .mb-n3 {
  margin-bottom: -12px !important;
}

.m-application .mb-n4 {
  margin-bottom: -16px !important;
}

.m-application .mb-n5 {
  margin-bottom: -20px !important;
}

.m-application .mb-n6 {
  margin-bottom: -24px !important;
}

.m-application .mb-n7 {
  margin-bottom: -28px !important;
}

.m-application .mb-n8 {
  margin-bottom: -32px !important;
}

.m-application .mb-n9 {
  margin-bottom: -36px !important;
}

.m-application .mb-n10 {
  margin-bottom: -40px !important;
}

.m-application .mb-n11 {
  margin-bottom: -44px !important;
}

.m-application .mb-n12 {
  margin-bottom: -48px !important;
}

.m-application .ml-n1 {
  margin-left: -4px !important;
}

.m-application .ml-n2 {
  margin-left: -8px !important;
}

.m-application .ml-n3 {
  margin-left: -12px !important;
}

.m-application .ml-n4 {
  margin-left: -16px !important;
}

.m-application .ml-n5 {
  margin-left: -20px !important;
}

.m-application .ml-n6 {
  margin-left: -24px !important;
}

.m-application .ml-n7 {
  margin-left: -28px !important;
}

.m-application .ml-n8 {
  margin-left: -32px !important;
}

.m-application .ml-n9 {
  margin-left: -36px !important;
}

.m-application .ml-n10 {
  margin-left: -40px !important;
}

.m-application .ml-n11 {
  margin-left: -44px !important;
}

.m-application .ml-n12 {
  margin-left: -48px !important;
}

[dir="ltr"] .m-application .ms-n1 {
  margin-left: -4px !important;
}

[dir="rtl"] .m-application .ms-n1 {
  margin-right: -4px !important;
}

[dir="ltr"] .m-application .ms-n2 {
  margin-left: -8px !important;
}

[dir="rtl"] .m-application .ms-n2 {
  margin-right: -8px !important;
}

[dir="ltr"] .m-application .ms-n3 {
  margin-left: -12px !important;
}

[dir="rtl"] .m-application .ms-n3 {
  margin-right: -12px !important;
}

[dir="ltr"] .m-application .ms-n4 {
  margin-left: -16px !important;
}

[dir="rtl"] .m-application .ms-n4 {
  margin-right: -16px !important;
}

[dir="ltr"] .m-application .ms-n5 {
  margin-left: -20px !important;
}

[dir="rtl"] .m-application .ms-n5 {
  margin-right: -20px !important;
}

[dir="ltr"] .m-application .ms-n6 {
  margin-left: -24px !important;
}

[dir="rtl"] .m-application .ms-n6 {
  margin-right: -24px !important;
}

[dir="ltr"] .m-application .ms-n7 {
  margin-left: -28px !important;
}

[dir="rtl"] .m-application .ms-n7 {
  margin-right: -28px !important;
}

[dir="ltr"] .m-application .ms-n8 {
  margin-left: -32px !important;
}

[dir="rtl"] .m-application .ms-n8 {
  margin-right: -32px !important;
}

[dir="ltr"] .m-application .ms-n9 {
  margin-left: -36px !important;
}

[dir="rtl"] .m-application .ms-n9 {
  margin-right: -36px !important;
}

[dir="ltr"] .m-application .ms-n10 {
  margin-left: -40px !important;
}

[dir="rtl"] .m-application .ms-n10 {
  margin-right: -40px !important;
}

[dir="ltr"] .m-application .ms-n11 {
  margin-left: -44px !important;
}

[dir="rtl"] .m-application .ms-n11 {
  margin-right: -44px !important;
}

[dir="ltr"] .m-application .ms-n12 {
  margin-left: -48px !important;
}

[dir="rtl"] .m-application .ms-n12 {
  margin-right: -48px !important;
}

[dir="ltr"] .m-application .me-n1 {
  margin-right: -4px !important;
}

[dir="rtl"] .m-application .me-n1 {
  margin-left: -4px !important;
}

[dir="ltr"] .m-application .me-n2 {
  margin-right: -8px !important;
}

[dir="rtl"] .m-application .me-n2 {
  margin-left: -8px !important;
}

[dir="ltr"] .m-application .me-n3 {
  margin-right: -12px !important;
}

[dir="rtl"] .m-application .me-n3 {
  margin-left: -12px !important;
}

[dir="ltr"] .m-application .me-n4 {
  margin-right: -16px !important;
}

[dir="rtl"] .m-application .me-n4 {
  margin-left: -16px !important;
}

[dir="ltr"] .m-application .me-n5 {
  margin-right: -20px !important;
}

[dir="rtl"] .m-application .me-n5 {
  margin-left: -20px !important;
}

[dir="ltr"] .m-application .me-n6 {
  margin-right: -24px !important;
}

[dir="rtl"] .m-application .me-n6 {
  margin-left: -24px !important;
}

[dir="ltr"] .m-application .me-n7 {
  margin-right: -28px !important;
}

[dir="rtl"] .m-application .me-n7 {
  margin-left: -28px !important;
}

[dir="ltr"] .m-application .me-n8 {
  margin-right: -32px !important;
}

[dir="rtl"] .m-application .me-n8 {
  margin-left: -32px !important;
}

[dir="ltr"] .m-application .me-n9 {
  margin-right: -36px !important;
}

[dir="rtl"] .m-application .me-n9 {
  margin-left: -36px !important;
}

[dir="ltr"] .m-application .me-n10 {
  margin-right: -40px !important;
}

[dir="rtl"] .m-application .me-n10 {
  margin-left: -40px !important;
}

[dir="ltr"] .m-application .me-n11 {
  margin-right: -44px !important;
}

[dir="rtl"] .m-application .me-n11 {
  margin-left: -44px !important;
}

[dir="ltr"] .m-application .me-n12 {
  margin-right: -48px !important;
}

[dir="rtl"] .m-application .me-n12 {
  margin-left: -48px !important;
}

.m-application .pa-0 {
  padding: 0px !important;
}

.m-application .pa-1 {
  padding: 4px !important;
}

.m-application .pa-2 {
  padding: 8px !important;
}

.m-application .pa-3 {
  padding: 12px !important;
}

.m-application .pa-4 {
  padding: 16px !important;
}

.m-application .pa-5 {
  padding: 20px !important;
}

.m-application .pa-6 {
  padding: 24px !important;
}

.m-application .pa-7 {
  padding: 28px !important;
}

.m-application .pa-8 {
  padding: 32px !important;
}

.m-application .pa-9 {
  padding: 36px !important;
}

.m-application .pa-10 {
  padding: 40px !important;
}

.m-application .pa-11 {
  padding: 44px !important;
}

.m-application .pa-12 {
  padding: 48px !important;
}

.m-application .px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.m-application .px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.m-application .px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.m-application .px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.m-application .px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.m-application .px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.m-application .px-6 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.m-application .px-7 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.m-application .px-8 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.m-application .px-9 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.m-application .px-10 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.m-application .px-11 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.m-application .px-12 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.m-application .py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.m-application .py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.m-application .py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.m-application .py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-application .py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.m-application .py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.m-application .py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.m-application .py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.m-application .py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.m-application .py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.m-application .py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.m-application .py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.m-application .py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.m-application .pt-0 {
  padding-top: 0px !important;
}

.m-application .pt-1 {
  padding-top: 4px !important;
}

.m-application .pt-2 {
  padding-top: 8px !important;
}

.m-application .pt-3 {
  padding-top: 12px !important;
}

.m-application .pt-4 {
  padding-top: 16px !important;
}

.m-application .pt-5 {
  padding-top: 20px !important;
}

.m-application .pt-6 {
  padding-top: 24px !important;
}

.m-application .pt-7 {
  padding-top: 28px !important;
}

.m-application .pt-8 {
  padding-top: 32px !important;
}

.m-application .pt-9 {
  padding-top: 36px !important;
}

.m-application .pt-10 {
  padding-top: 40px !important;
}

.m-application .pt-11 {
  padding-top: 44px !important;
}

.m-application .pt-12 {
  padding-top: 48px !important;
}

.m-application .pr-0 {
  padding-right: 0px !important;
}

.m-application .pr-1 {
  padding-right: 4px !important;
}

.m-application .pr-2 {
  padding-right: 8px !important;
}

.m-application .pr-3 {
  padding-right: 12px !important;
}

.m-application .pr-4 {
  padding-right: 16px !important;
}

.m-application .pr-5 {
  padding-right: 20px !important;
}

.m-application .pr-6 {
  padding-right: 24px !important;
}

.m-application .pr-7 {
  padding-right: 28px !important;
}

.m-application .pr-8 {
  padding-right: 32px !important;
}

.m-application .pr-9 {
  padding-right: 36px !important;
}

.m-application .pr-10 {
  padding-right: 40px !important;
}

.m-application .pr-11 {
  padding-right: 44px !important;
}

.m-application .pr-12 {
  padding-right: 48px !important;
}

.m-application .pb-0 {
  padding-bottom: 0px !important;
}

.m-application .pb-1 {
  padding-bottom: 4px !important;
}

.m-application .pb-2 {
  padding-bottom: 8px !important;
}

.m-application .pb-3 {
  padding-bottom: 12px !important;
}

.m-application .pb-4 {
  padding-bottom: 16px !important;
}

.m-application .pb-5 {
  padding-bottom: 20px !important;
}

.m-application .pb-6 {
  padding-bottom: 24px !important;
}

.m-application .pb-7 {
  padding-bottom: 28px !important;
}

.m-application .pb-8 {
  padding-bottom: 32px !important;
}

.m-application .pb-9 {
  padding-bottom: 36px !important;
}

.m-application .pb-10 {
  padding-bottom: 40px !important;
}

.m-application .pb-11 {
  padding-bottom: 44px !important;
}

.m-application .pb-12 {
  padding-bottom: 48px !important;
}

.m-application .pl-0 {
  padding-left: 0px !important;
}

.m-application .pl-1 {
  padding-left: 4px !important;
}

.m-application .pl-2 {
  padding-left: 8px !important;
}

.m-application .pl-3 {
  padding-left: 12px !important;
}

.m-application .pl-4 {
  padding-left: 16px !important;
}

.m-application .pl-5 {
  padding-left: 20px !important;
}

.m-application .pl-6 {
  padding-left: 24px !important;
}

.m-application .pl-7 {
  padding-left: 28px !important;
}

.m-application .pl-8 {
  padding-left: 32px !important;
}

.m-application .pl-9 {
  padding-left: 36px !important;
}

.m-application .pl-10 {
  padding-left: 40px !important;
}

.m-application .pl-11 {
  padding-left: 44px !important;
}

.m-application .pl-12 {
  padding-left: 48px !important;
}

[dir="ltr"] .m-application .ps-0 {
  padding-left: 0px !important;
}

[dir="rtl"] .m-application .ps-0 {
  padding-right: 0px !important;
}

[dir="ltr"] .m-application .ps-1 {
  padding-left: 4px !important;
}

[dir="rtl"] .m-application .ps-1 {
  padding-right: 4px !important;
}

[dir="ltr"] .m-application .ps-2 {
  padding-left: 8px !important;
}

[dir="rtl"] .m-application .ps-2 {
  padding-right: 8px !important;
}

[dir="ltr"] .m-application .ps-3 {
  padding-left: 12px !important;
}

[dir="rtl"] .m-application .ps-3 {
  padding-right: 12px !important;
}

[dir="ltr"] .m-application .ps-4 {
  padding-left: 16px !important;
}

[dir="rtl"] .m-application .ps-4 {
  padding-right: 16px !important;
}

[dir="ltr"] .m-application .ps-5 {
  padding-left: 20px !important;
}

[dir="rtl"] .m-application .ps-5 {
  padding-right: 20px !important;
}

[dir="ltr"] .m-application .ps-6 {
  padding-left: 24px !important;
}

[dir="rtl"] .m-application .ps-6 {
  padding-right: 24px !important;
}

[dir="ltr"] .m-application .ps-7 {
  padding-left: 28px !important;
}

[dir="rtl"] .m-application .ps-7 {
  padding-right: 28px !important;
}

[dir="ltr"] .m-application .ps-8 {
  padding-left: 32px !important;
}

[dir="rtl"] .m-application .ps-8 {
  padding-right: 32px !important;
}

[dir="ltr"] .m-application .ps-9 {
  padding-left: 36px !important;
}

[dir="rtl"] .m-application .ps-9 {
  padding-right: 36px !important;
}

[dir="ltr"] .m-application .ps-10 {
  padding-left: 40px !important;
}

[dir="rtl"] .m-application .ps-10 {
  padding-right: 40px !important;
}

[dir="ltr"] .m-application .ps-11 {
  padding-left: 44px !important;
}

[dir="rtl"] .m-application .ps-11 {
  padding-right: 44px !important;
}

[dir="ltr"] .m-application .ps-12 {
  padding-left: 48px !important;
}

[dir="rtl"] .m-application .ps-12 {
  padding-right: 48px !important;
}

[dir="ltr"] .m-application .pe-0 {
  padding-right: 0px !important;
}

[dir="rtl"] .m-application .pe-0 {
  padding-left: 0px !important;
}

[dir="ltr"] .m-application .pe-1 {
  padding-right: 4px !important;
}

[dir="rtl"] .m-application .pe-1 {
  padding-left: 4px !important;
}

[dir="ltr"] .m-application .pe-2 {
  padding-right: 8px !important;
}

[dir="rtl"] .m-application .pe-2 {
  padding-left: 8px !important;
}

[dir="ltr"] .m-application .pe-3 {
  padding-right: 12px !important;
}

[dir="rtl"] .m-application .pe-3 {
  padding-left: 12px !important;
}

[dir="ltr"] .m-application .pe-4 {
  padding-right: 16px !important;
}

[dir="rtl"] .m-application .pe-4 {
  padding-left: 16px !important;
}

[dir="ltr"] .m-application .pe-5 {
  padding-right: 20px !important;
}

[dir="rtl"] .m-application .pe-5 {
  padding-left: 20px !important;
}

[dir="ltr"] .m-application .pe-6 {
  padding-right: 24px !important;
}

[dir="rtl"] .m-application .pe-6 {
  padding-left: 24px !important;
}

[dir="ltr"] .m-application .pe-7 {
  padding-right: 28px !important;
}

[dir="rtl"] .m-application .pe-7 {
  padding-left: 28px !important;
}

[dir="ltr"] .m-application .pe-8 {
  padding-right: 32px !important;
}

[dir="rtl"] .m-application .pe-8 {
  padding-left: 32px !important;
}

[dir="ltr"] .m-application .pe-9 {
  padding-right: 36px !important;
}

[dir="rtl"] .m-application .pe-9 {
  padding-left: 36px !important;
}

[dir="ltr"] .m-application .pe-10 {
  padding-right: 40px !important;
}

[dir="rtl"] .m-application .pe-10 {
  padding-left: 40px !important;
}

[dir="ltr"] .m-application .pe-11 {
  padding-right: 44px !important;
}

[dir="rtl"] .m-application .pe-11 {
  padding-left: 44px !important;
}

[dir="ltr"] .m-application .pe-12 {
  padding-right: 48px !important;
}

[dir="rtl"] .m-application .pe-12 {
  padding-left: 48px !important;
}

@media only screen and (min-width: 600px) {
  .m-application .ma-sm-0 {
    margin: 0px !important;
  }

  .m-application .ma-sm-1 {
    margin: 4px !important;
  }

  .m-application .ma-sm-2 {
    margin: 8px !important;
  }

  .m-application .ma-sm-3 {
    margin: 12px !important;
  }

  .m-application .ma-sm-4 {
    margin: 16px !important;
  }

  .m-application .ma-sm-5 {
    margin: 20px !important;
  }

  .m-application .ma-sm-6 {
    margin: 24px !important;
  }

  .m-application .ma-sm-7 {
    margin: 28px !important;
  }

  .m-application .ma-sm-8 {
    margin: 32px !important;
  }

  .m-application .ma-sm-9 {
    margin: 36px !important;
  }

  .m-application .ma-sm-10 {
    margin: 40px !important;
  }

  .m-application .ma-sm-11 {
    margin: 44px !important;
  }

  .m-application .ma-sm-12 {
    margin: 48px !important;
  }

  .m-application .ma-sm-auto {
    margin: auto !important;
  }

  .m-application .mx-sm-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .m-application .mx-sm-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .m-application .mx-sm-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .m-application .mx-sm-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .m-application .mx-sm-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .m-application .mx-sm-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .m-application .mx-sm-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .m-application .mx-sm-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }

  .m-application .mx-sm-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .m-application .mx-sm-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .m-application .mx-sm-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .m-application .mx-sm-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }

  .m-application .mx-sm-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .m-application .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .m-application .my-sm-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .m-application .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .m-application .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .m-application .my-sm-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .m-application .my-sm-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .m-application .my-sm-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-application .my-sm-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .m-application .my-sm-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .m-application .my-sm-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .m-application .my-sm-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .m-application .my-sm-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-application .my-sm-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .m-application .my-sm-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .m-application .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-application .mt-sm-0 {
    margin-top: 0px !important;
  }

  .m-application .mt-sm-1 {
    margin-top: 4px !important;
  }

  .m-application .mt-sm-2 {
    margin-top: 8px !important;
  }

  .m-application .mt-sm-3 {
    margin-top: 12px !important;
  }

  .m-application .mt-sm-4 {
    margin-top: 16px !important;
  }

  .m-application .mt-sm-5 {
    margin-top: 20px !important;
  }

  .m-application .mt-sm-6 {
    margin-top: 24px !important;
  }

  .m-application .mt-sm-7 {
    margin-top: 28px !important;
  }

  .m-application .mt-sm-8 {
    margin-top: 32px !important;
  }

  .m-application .mt-sm-9 {
    margin-top: 36px !important;
  }

  .m-application .mt-sm-10 {
    margin-top: 40px !important;
  }

  .m-application .mt-sm-11 {
    margin-top: 44px !important;
  }

  .m-application .mt-sm-12 {
    margin-top: 48px !important;
  }

  .m-application .mt-sm-auto {
    margin-top: auto !important;
  }

  .m-application .mr-sm-0 {
    margin-right: 0px !important;
  }

  .m-application .mr-sm-1 {
    margin-right: 4px !important;
  }

  .m-application .mr-sm-2 {
    margin-right: 8px !important;
  }

  .m-application .mr-sm-3 {
    margin-right: 12px !important;
  }

  .m-application .mr-sm-4 {
    margin-right: 16px !important;
  }

  .m-application .mr-sm-5 {
    margin-right: 20px !important;
  }

  .m-application .mr-sm-6 {
    margin-right: 24px !important;
  }

  .m-application .mr-sm-7 {
    margin-right: 28px !important;
  }

  .m-application .mr-sm-8 {
    margin-right: 32px !important;
  }

  .m-application .mr-sm-9 {
    margin-right: 36px !important;
  }

  .m-application .mr-sm-10 {
    margin-right: 40px !important;
  }

  .m-application .mr-sm-11 {
    margin-right: 44px !important;
  }

  .m-application .mr-sm-12 {
    margin-right: 48px !important;
  }

  .m-application .mr-sm-auto {
    margin-right: auto !important;
  }

  .m-application .mb-sm-0 {
    margin-bottom: 0px !important;
  }

  .m-application .mb-sm-1 {
    margin-bottom: 4px !important;
  }

  .m-application .mb-sm-2 {
    margin-bottom: 8px !important;
  }

  .m-application .mb-sm-3 {
    margin-bottom: 12px !important;
  }

  .m-application .mb-sm-4 {
    margin-bottom: 16px !important;
  }

  .m-application .mb-sm-5 {
    margin-bottom: 20px !important;
  }

  .m-application .mb-sm-6 {
    margin-bottom: 24px !important;
  }

  .m-application .mb-sm-7 {
    margin-bottom: 28px !important;
  }

  .m-application .mb-sm-8 {
    margin-bottom: 32px !important;
  }

  .m-application .mb-sm-9 {
    margin-bottom: 36px !important;
  }

  .m-application .mb-sm-10 {
    margin-bottom: 40px !important;
  }

  .m-application .mb-sm-11 {
    margin-bottom: 44px !important;
  }

  .m-application .mb-sm-12 {
    margin-bottom: 48px !important;
  }

  .m-application .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .m-application .ml-sm-0 {
    margin-left: 0px !important;
  }

  .m-application .ml-sm-1 {
    margin-left: 4px !important;
  }

  .m-application .ml-sm-2 {
    margin-left: 8px !important;
  }

  .m-application .ml-sm-3 {
    margin-left: 12px !important;
  }

  .m-application .ml-sm-4 {
    margin-left: 16px !important;
  }

  .m-application .ml-sm-5 {
    margin-left: 20px !important;
  }

  .m-application .ml-sm-6 {
    margin-left: 24px !important;
  }

  .m-application .ml-sm-7 {
    margin-left: 28px !important;
  }

  .m-application .ml-sm-8 {
    margin-left: 32px !important;
  }

  .m-application .ml-sm-9 {
    margin-left: 36px !important;
  }

  .m-application .ml-sm-10 {
    margin-left: 40px !important;
  }

  .m-application .ml-sm-11 {
    margin-left: 44px !important;
  }

  .m-application .ml-sm-12 {
    margin-left: 48px !important;
  }

  .m-application .ml-sm-auto {
    margin-left: auto !important;
  }

  [dir="ltr"] .m-application .ms-sm-0 {
    margin-left: 0px !important;
  }

  [dir="rtl"] .m-application .ms-sm-0 {
    margin-right: 0px !important;
  }

  [dir="ltr"] .m-application .ms-sm-1 {
    margin-left: 4px !important;
  }

  [dir="rtl"] .m-application .ms-sm-1 {
    margin-right: 4px !important;
  }

  [dir="ltr"] .m-application .ms-sm-2 {
    margin-left: 8px !important;
  }

  [dir="rtl"] .m-application .ms-sm-2 {
    margin-right: 8px !important;
  }

  [dir="ltr"] .m-application .ms-sm-3 {
    margin-left: 12px !important;
  }

  [dir="rtl"] .m-application .ms-sm-3 {
    margin-right: 12px !important;
  }

  [dir="ltr"] .m-application .ms-sm-4 {
    margin-left: 16px !important;
  }

  [dir="rtl"] .m-application .ms-sm-4 {
    margin-right: 16px !important;
  }

  [dir="ltr"] .m-application .ms-sm-5 {
    margin-left: 20px !important;
  }

  [dir="rtl"] .m-application .ms-sm-5 {
    margin-right: 20px !important;
  }

  [dir="ltr"] .m-application .ms-sm-6 {
    margin-left: 24px !important;
  }

  [dir="rtl"] .m-application .ms-sm-6 {
    margin-right: 24px !important;
  }

  [dir="ltr"] .m-application .ms-sm-7 {
    margin-left: 28px !important;
  }

  [dir="rtl"] .m-application .ms-sm-7 {
    margin-right: 28px !important;
  }

  [dir="ltr"] .m-application .ms-sm-8 {
    margin-left: 32px !important;
  }

  [dir="rtl"] .m-application .ms-sm-8 {
    margin-right: 32px !important;
  }

  [dir="ltr"] .m-application .ms-sm-9 {
    margin-left: 36px !important;
  }

  [dir="rtl"] .m-application .ms-sm-9 {
    margin-right: 36px !important;
  }

  [dir="ltr"] .m-application .ms-sm-10 {
    margin-left: 40px !important;
  }

  [dir="rtl"] .m-application .ms-sm-10 {
    margin-right: 40px !important;
  }

  [dir="ltr"] .m-application .ms-sm-11 {
    margin-left: 44px !important;
  }

  [dir="rtl"] .m-application .ms-sm-11 {
    margin-right: 44px !important;
  }

  [dir="ltr"] .m-application .ms-sm-12 {
    margin-left: 48px !important;
  }

  [dir="rtl"] .m-application .ms-sm-12 {
    margin-right: 48px !important;
  }

  [dir="ltr"] .m-application .ms-sm-auto {
    margin-left: auto !important;
  }

  [dir="rtl"] .m-application .ms-sm-auto {
    margin-right: auto !important;
  }

  [dir="ltr"] .m-application .me-sm-0 {
    margin-right: 0px !important;
  }

  [dir="rtl"] .m-application .me-sm-0 {
    margin-left: 0px !important;
  }

  [dir="ltr"] .m-application .me-sm-1 {
    margin-right: 4px !important;
  }

  [dir="rtl"] .m-application .me-sm-1 {
    margin-left: 4px !important;
  }

  [dir="ltr"] .m-application .me-sm-2 {
    margin-right: 8px !important;
  }

  [dir="rtl"] .m-application .me-sm-2 {
    margin-left: 8px !important;
  }

  [dir="ltr"] .m-application .me-sm-3 {
    margin-right: 12px !important;
  }

  [dir="rtl"] .m-application .me-sm-3 {
    margin-left: 12px !important;
  }

  [dir="ltr"] .m-application .me-sm-4 {
    margin-right: 16px !important;
  }

  [dir="rtl"] .m-application .me-sm-4 {
    margin-left: 16px !important;
  }

  [dir="ltr"] .m-application .me-sm-5 {
    margin-right: 20px !important;
  }

  [dir="rtl"] .m-application .me-sm-5 {
    margin-left: 20px !important;
  }

  [dir="ltr"] .m-application .me-sm-6 {
    margin-right: 24px !important;
  }

  [dir="rtl"] .m-application .me-sm-6 {
    margin-left: 24px !important;
  }

  [dir="ltr"] .m-application .me-sm-7 {
    margin-right: 28px !important;
  }

  [dir="rtl"] .m-application .me-sm-7 {
    margin-left: 28px !important;
  }

  [dir="ltr"] .m-application .me-sm-8 {
    margin-right: 32px !important;
  }

  [dir="rtl"] .m-application .me-sm-8 {
    margin-left: 32px !important;
  }

  [dir="ltr"] .m-application .me-sm-9 {
    margin-right: 36px !important;
  }

  [dir="rtl"] .m-application .me-sm-9 {
    margin-left: 36px !important;
  }

  [dir="ltr"] .m-application .me-sm-10 {
    margin-right: 40px !important;
  }

  [dir="rtl"] .m-application .me-sm-10 {
    margin-left: 40px !important;
  }

  [dir="ltr"] .m-application .me-sm-11 {
    margin-right: 44px !important;
  }

  [dir="rtl"] .m-application .me-sm-11 {
    margin-left: 44px !important;
  }

  [dir="ltr"] .m-application .me-sm-12 {
    margin-right: 48px !important;
  }

  [dir="rtl"] .m-application .me-sm-12 {
    margin-left: 48px !important;
  }

  [dir="ltr"] .m-application .me-sm-auto {
    margin-right: auto !important;
  }

  [dir="rtl"] .m-application .me-sm-auto {
    margin-left: auto !important;
  }

  .m-application .ma-sm-n1 {
    margin: -4px !important;
  }

  .m-application .ma-sm-n2 {
    margin: -8px !important;
  }

  .m-application .ma-sm-n3 {
    margin: -12px !important;
  }

  .m-application .ma-sm-n4 {
    margin: -16px !important;
  }

  .m-application .ma-sm-n5 {
    margin: -20px !important;
  }

  .m-application .ma-sm-n6 {
    margin: -24px !important;
  }

  .m-application .ma-sm-n7 {
    margin: -28px !important;
  }

  .m-application .ma-sm-n8 {
    margin: -32px !important;
  }

  .m-application .ma-sm-n9 {
    margin: -36px !important;
  }

  .m-application .ma-sm-n10 {
    margin: -40px !important;
  }

  .m-application .ma-sm-n11 {
    margin: -44px !important;
  }

  .m-application .ma-sm-n12 {
    margin: -48px !important;
  }

  .m-application .mx-sm-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .m-application .mx-sm-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .m-application .mx-sm-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .m-application .mx-sm-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .m-application .mx-sm-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .m-application .mx-sm-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .m-application .mx-sm-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }

  .m-application .mx-sm-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .m-application .mx-sm-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .m-application .mx-sm-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .m-application .mx-sm-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }

  .m-application .mx-sm-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .m-application .my-sm-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .m-application .my-sm-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .m-application .my-sm-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .m-application .my-sm-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .m-application .my-sm-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m-application .my-sm-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .m-application .my-sm-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .m-application .my-sm-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .m-application .my-sm-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .m-application .my-sm-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m-application .my-sm-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .m-application .my-sm-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .m-application .mt-sm-n1 {
    margin-top: -4px !important;
  }

  .m-application .mt-sm-n2 {
    margin-top: -8px !important;
  }

  .m-application .mt-sm-n3 {
    margin-top: -12px !important;
  }

  .m-application .mt-sm-n4 {
    margin-top: -16px !important;
  }

  .m-application .mt-sm-n5 {
    margin-top: -20px !important;
  }

  .m-application .mt-sm-n6 {
    margin-top: -24px !important;
  }

  .m-application .mt-sm-n7 {
    margin-top: -28px !important;
  }

  .m-application .mt-sm-n8 {
    margin-top: -32px !important;
  }

  .m-application .mt-sm-n9 {
    margin-top: -36px !important;
  }

  .m-application .mt-sm-n10 {
    margin-top: -40px !important;
  }

  .m-application .mt-sm-n11 {
    margin-top: -44px !important;
  }

  .m-application .mt-sm-n12 {
    margin-top: -48px !important;
  }

  .m-application .mr-sm-n1 {
    margin-right: -4px !important;
  }

  .m-application .mr-sm-n2 {
    margin-right: -8px !important;
  }

  .m-application .mr-sm-n3 {
    margin-right: -12px !important;
  }

  .m-application .mr-sm-n4 {
    margin-right: -16px !important;
  }

  .m-application .mr-sm-n5 {
    margin-right: -20px !important;
  }

  .m-application .mr-sm-n6 {
    margin-right: -24px !important;
  }

  .m-application .mr-sm-n7 {
    margin-right: -28px !important;
  }

  .m-application .mr-sm-n8 {
    margin-right: -32px !important;
  }

  .m-application .mr-sm-n9 {
    margin-right: -36px !important;
  }

  .m-application .mr-sm-n10 {
    margin-right: -40px !important;
  }

  .m-application .mr-sm-n11 {
    margin-right: -44px !important;
  }

  .m-application .mr-sm-n12 {
    margin-right: -48px !important;
  }

  .m-application .mb-sm-n1 {
    margin-bottom: -4px !important;
  }

  .m-application .mb-sm-n2 {
    margin-bottom: -8px !important;
  }

  .m-application .mb-sm-n3 {
    margin-bottom: -12px !important;
  }

  .m-application .mb-sm-n4 {
    margin-bottom: -16px !important;
  }

  .m-application .mb-sm-n5 {
    margin-bottom: -20px !important;
  }

  .m-application .mb-sm-n6 {
    margin-bottom: -24px !important;
  }

  .m-application .mb-sm-n7 {
    margin-bottom: -28px !important;
  }

  .m-application .mb-sm-n8 {
    margin-bottom: -32px !important;
  }

  .m-application .mb-sm-n9 {
    margin-bottom: -36px !important;
  }

  .m-application .mb-sm-n10 {
    margin-bottom: -40px !important;
  }

  .m-application .mb-sm-n11 {
    margin-bottom: -44px !important;
  }

  .m-application .mb-sm-n12 {
    margin-bottom: -48px !important;
  }

  .m-application .ml-sm-n1 {
    margin-left: -4px !important;
  }

  .m-application .ml-sm-n2 {
    margin-left: -8px !important;
  }

  .m-application .ml-sm-n3 {
    margin-left: -12px !important;
  }

  .m-application .ml-sm-n4 {
    margin-left: -16px !important;
  }

  .m-application .ml-sm-n5 {
    margin-left: -20px !important;
  }

  .m-application .ml-sm-n6 {
    margin-left: -24px !important;
  }

  .m-application .ml-sm-n7 {
    margin-left: -28px !important;
  }

  .m-application .ml-sm-n8 {
    margin-left: -32px !important;
  }

  .m-application .ml-sm-n9 {
    margin-left: -36px !important;
  }

  .m-application .ml-sm-n10 {
    margin-left: -40px !important;
  }

  .m-application .ml-sm-n11 {
    margin-left: -44px !important;
  }

  .m-application .ml-sm-n12 {
    margin-left: -48px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n1 {
    margin-left: -4px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n1 {
    margin-right: -4px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n2 {
    margin-left: -8px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n2 {
    margin-right: -8px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n3 {
    margin-left: -12px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n3 {
    margin-right: -12px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n4 {
    margin-left: -16px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n4 {
    margin-right: -16px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n5 {
    margin-left: -20px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n5 {
    margin-right: -20px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n6 {
    margin-left: -24px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n6 {
    margin-right: -24px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n7 {
    margin-left: -28px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n7 {
    margin-right: -28px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n8 {
    margin-left: -32px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n8 {
    margin-right: -32px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n9 {
    margin-left: -36px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n9 {
    margin-right: -36px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n10 {
    margin-left: -40px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n10 {
    margin-right: -40px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n11 {
    margin-left: -44px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n11 {
    margin-right: -44px !important;
  }

  [dir="ltr"] .m-application .ms-sm-n12 {
    margin-left: -48px !important;
  }

  [dir="rtl"] .m-application .ms-sm-n12 {
    margin-right: -48px !important;
  }

  [dir="ltr"] .m-application .me-sm-n1 {
    margin-right: -4px !important;
  }

  [dir="rtl"] .m-application .me-sm-n1 {
    margin-left: -4px !important;
  }

  [dir="ltr"] .m-application .me-sm-n2 {
    margin-right: -8px !important;
  }

  [dir="rtl"] .m-application .me-sm-n2 {
    margin-left: -8px !important;
  }

  [dir="ltr"] .m-application .me-sm-n3 {
    margin-right: -12px !important;
  }

  [dir="rtl"] .m-application .me-sm-n3 {
    margin-left: -12px !important;
  }

  [dir="ltr"] .m-application .me-sm-n4 {
    margin-right: -16px !important;
  }

  [dir="rtl"] .m-application .me-sm-n4 {
    margin-left: -16px !important;
  }

  [dir="ltr"] .m-application .me-sm-n5 {
    margin-right: -20px !important;
  }

  [dir="rtl"] .m-application .me-sm-n5 {
    margin-left: -20px !important;
  }

  [dir="ltr"] .m-application .me-sm-n6 {
    margin-right: -24px !important;
  }

  [dir="rtl"] .m-application .me-sm-n6 {
    margin-left: -24px !important;
  }

  [dir="ltr"] .m-application .me-sm-n7 {
    margin-right: -28px !important;
  }

  [dir="rtl"] .m-application .me-sm-n7 {
    margin-left: -28px !important;
  }

  [dir="ltr"] .m-application .me-sm-n8 {
    margin-right: -32px !important;
  }

  [dir="rtl"] .m-application .me-sm-n8 {
    margin-left: -32px !important;
  }

  [dir="ltr"] .m-application .me-sm-n9 {
    margin-right: -36px !important;
  }

  [dir="rtl"] .m-application .me-sm-n9 {
    margin-left: -36px !important;
  }

  [dir="ltr"] .m-application .me-sm-n10 {
    margin-right: -40px !important;
  }

  [dir="rtl"] .m-application .me-sm-n10 {
    margin-left: -40px !important;
  }

  [dir="ltr"] .m-application .me-sm-n11 {
    margin-right: -44px !important;
  }

  [dir="rtl"] .m-application .me-sm-n11 {
    margin-left: -44px !important;
  }

  [dir="ltr"] .m-application .me-sm-n12 {
    margin-right: -48px !important;
  }

  [dir="rtl"] .m-application .me-sm-n12 {
    margin-left: -48px !important;
  }

  .m-application .pa-sm-0 {
    padding: 0px !important;
  }

  .m-application .pa-sm-1 {
    padding: 4px !important;
  }

  .m-application .pa-sm-2 {
    padding: 8px !important;
  }

  .m-application .pa-sm-3 {
    padding: 12px !important;
  }

  .m-application .pa-sm-4 {
    padding: 16px !important;
  }

  .m-application .pa-sm-5 {
    padding: 20px !important;
  }

  .m-application .pa-sm-6 {
    padding: 24px !important;
  }

  .m-application .pa-sm-7 {
    padding: 28px !important;
  }

  .m-application .pa-sm-8 {
    padding: 32px !important;
  }

  .m-application .pa-sm-9 {
    padding: 36px !important;
  }

  .m-application .pa-sm-10 {
    padding: 40px !important;
  }

  .m-application .pa-sm-11 {
    padding: 44px !important;
  }

  .m-application .pa-sm-12 {
    padding: 48px !important;
  }

  .m-application .px-sm-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .m-application .px-sm-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .m-application .px-sm-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .m-application .px-sm-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .m-application .px-sm-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .m-application .px-sm-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .m-application .px-sm-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .m-application .px-sm-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }

  .m-application .px-sm-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .m-application .px-sm-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .m-application .px-sm-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .m-application .px-sm-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }

  .m-application .px-sm-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .m-application .py-sm-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .m-application .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .m-application .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .m-application .py-sm-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .m-application .py-sm-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .m-application .py-sm-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .m-application .py-sm-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .m-application .py-sm-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .m-application .py-sm-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .m-application .py-sm-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .m-application .py-sm-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .m-application .py-sm-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .m-application .py-sm-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .m-application .pt-sm-0 {
    padding-top: 0px !important;
  }

  .m-application .pt-sm-1 {
    padding-top: 4px !important;
  }

  .m-application .pt-sm-2 {
    padding-top: 8px !important;
  }

  .m-application .pt-sm-3 {
    padding-top: 12px !important;
  }

  .m-application .pt-sm-4 {
    padding-top: 16px !important;
  }

  .m-application .pt-sm-5 {
    padding-top: 20px !important;
  }

  .m-application .pt-sm-6 {
    padding-top: 24px !important;
  }

  .m-application .pt-sm-7 {
    padding-top: 28px !important;
  }

  .m-application .pt-sm-8 {
    padding-top: 32px !important;
  }

  .m-application .pt-sm-9 {
    padding-top: 36px !important;
  }

  .m-application .pt-sm-10 {
    padding-top: 40px !important;
  }

  .m-application .pt-sm-11 {
    padding-top: 44px !important;
  }

  .m-application .pt-sm-12 {
    padding-top: 48px !important;
  }

  .m-application .pr-sm-0 {
    padding-right: 0px !important;
  }

  .m-application .pr-sm-1 {
    padding-right: 4px !important;
  }

  .m-application .pr-sm-2 {
    padding-right: 8px !important;
  }

  .m-application .pr-sm-3 {
    padding-right: 12px !important;
  }

  .m-application .pr-sm-4 {
    padding-right: 16px !important;
  }

  .m-application .pr-sm-5 {
    padding-right: 20px !important;
  }

  .m-application .pr-sm-6 {
    padding-right: 24px !important;
  }

  .m-application .pr-sm-7 {
    padding-right: 28px !important;
  }

  .m-application .pr-sm-8 {
    padding-right: 32px !important;
  }

  .m-application .pr-sm-9 {
    padding-right: 36px !important;
  }

  .m-application .pr-sm-10 {
    padding-right: 40px !important;
  }

  .m-application .pr-sm-11 {
    padding-right: 44px !important;
  }

  .m-application .pr-sm-12 {
    padding-right: 48px !important;
  }

  .m-application .pb-sm-0 {
    padding-bottom: 0px !important;
  }

  .m-application .pb-sm-1 {
    padding-bottom: 4px !important;
  }

  .m-application .pb-sm-2 {
    padding-bottom: 8px !important;
  }

  .m-application .pb-sm-3 {
    padding-bottom: 12px !important;
  }

  .m-application .pb-sm-4 {
    padding-bottom: 16px !important;
  }

  .m-application .pb-sm-5 {
    padding-bottom: 20px !important;
  }

  .m-application .pb-sm-6 {
    padding-bottom: 24px !important;
  }

  .m-application .pb-sm-7 {
    padding-bottom: 28px !important;
  }

  .m-application .pb-sm-8 {
    padding-bottom: 32px !important;
  }

  .m-application .pb-sm-9 {
    padding-bottom: 36px !important;
  }

  .m-application .pb-sm-10 {
    padding-bottom: 40px !important;
  }

  .m-application .pb-sm-11 {
    padding-bottom: 44px !important;
  }

  .m-application .pb-sm-12 {
    padding-bottom: 48px !important;
  }

  .m-application .pl-sm-0 {
    padding-left: 0px !important;
  }

  .m-application .pl-sm-1 {
    padding-left: 4px !important;
  }

  .m-application .pl-sm-2 {
    padding-left: 8px !important;
  }

  .m-application .pl-sm-3 {
    padding-left: 12px !important;
  }

  .m-application .pl-sm-4 {
    padding-left: 16px !important;
  }

  .m-application .pl-sm-5 {
    padding-left: 20px !important;
  }

  .m-application .pl-sm-6 {
    padding-left: 24px !important;
  }

  .m-application .pl-sm-7 {
    padding-left: 28px !important;
  }

  .m-application .pl-sm-8 {
    padding-left: 32px !important;
  }

  .m-application .pl-sm-9 {
    padding-left: 36px !important;
  }

  .m-application .pl-sm-10 {
    padding-left: 40px !important;
  }

  .m-application .pl-sm-11 {
    padding-left: 44px !important;
  }

  .m-application .pl-sm-12 {
    padding-left: 48px !important;
  }

  [dir="ltr"] .m-application .ps-sm-0 {
    padding-left: 0px !important;
  }

  [dir="rtl"] .m-application .ps-sm-0 {
    padding-right: 0px !important;
  }

  [dir="ltr"] .m-application .ps-sm-1 {
    padding-left: 4px !important;
  }

  [dir="rtl"] .m-application .ps-sm-1 {
    padding-right: 4px !important;
  }

  [dir="ltr"] .m-application .ps-sm-2 {
    padding-left: 8px !important;
  }

  [dir="rtl"] .m-application .ps-sm-2 {
    padding-right: 8px !important;
  }

  [dir="ltr"] .m-application .ps-sm-3 {
    padding-left: 12px !important;
  }

  [dir="rtl"] .m-application .ps-sm-3 {
    padding-right: 12px !important;
  }

  [dir="ltr"] .m-application .ps-sm-4 {
    padding-left: 16px !important;
  }

  [dir="rtl"] .m-application .ps-sm-4 {
    padding-right: 16px !important;
  }

  [dir="ltr"] .m-application .ps-sm-5 {
    padding-left: 20px !important;
  }

  [dir="rtl"] .m-application .ps-sm-5 {
    padding-right: 20px !important;
  }

  [dir="ltr"] .m-application .ps-sm-6 {
    padding-left: 24px !important;
  }

  [dir="rtl"] .m-application .ps-sm-6 {
    padding-right: 24px !important;
  }

  [dir="ltr"] .m-application .ps-sm-7 {
    padding-left: 28px !important;
  }

  [dir="rtl"] .m-application .ps-sm-7 {
    padding-right: 28px !important;
  }

  [dir="ltr"] .m-application .ps-sm-8 {
    padding-left: 32px !important;
  }

  [dir="rtl"] .m-application .ps-sm-8 {
    padding-right: 32px !important;
  }

  [dir="ltr"] .m-application .ps-sm-9 {
    padding-left: 36px !important;
  }

  [dir="rtl"] .m-application .ps-sm-9 {
    padding-right: 36px !important;
  }

  [dir="ltr"] .m-application .ps-sm-10 {
    padding-left: 40px !important;
  }

  [dir="rtl"] .m-application .ps-sm-10 {
    padding-right: 40px !important;
  }

  [dir="ltr"] .m-application .ps-sm-11 {
    padding-left: 44px !important;
  }

  [dir="rtl"] .m-application .ps-sm-11 {
    padding-right: 44px !important;
  }

  [dir="ltr"] .m-application .ps-sm-12 {
    padding-left: 48px !important;
  }

  [dir="rtl"] .m-application .ps-sm-12 {
    padding-right: 48px !important;
  }

  [dir="ltr"] .m-application .pe-sm-0 {
    padding-right: 0px !important;
  }

  [dir="rtl"] .m-application .pe-sm-0 {
    padding-left: 0px !important;
  }

  [dir="ltr"] .m-application .pe-sm-1 {
    padding-right: 4px !important;
  }

  [dir="rtl"] .m-application .pe-sm-1 {
    padding-left: 4px !important;
  }

  [dir="ltr"] .m-application .pe-sm-2 {
    padding-right: 8px !important;
  }

  [dir="rtl"] .m-application .pe-sm-2 {
    padding-left: 8px !important;
  }

  [dir="ltr"] .m-application .pe-sm-3 {
    padding-right: 12px !important;
  }

  [dir="rtl"] .m-application .pe-sm-3 {
    padding-left: 12px !important;
  }

  [dir="ltr"] .m-application .pe-sm-4 {
    padding-right: 16px !important;
  }

  [dir="rtl"] .m-application .pe-sm-4 {
    padding-left: 16px !important;
  }

  [dir="ltr"] .m-application .pe-sm-5 {
    padding-right: 20px !important;
  }

  [dir="rtl"] .m-application .pe-sm-5 {
    padding-left: 20px !important;
  }

  [dir="ltr"] .m-application .pe-sm-6 {
    padding-right: 24px !important;
  }

  [dir="rtl"] .m-application .pe-sm-6 {
    padding-left: 24px !important;
  }

  [dir="ltr"] .m-application .pe-sm-7 {
    padding-right: 28px !important;
  }

  [dir="rtl"] .m-application .pe-sm-7 {
    padding-left: 28px !important;
  }

  [dir="ltr"] .m-application .pe-sm-8 {
    padding-right: 32px !important;
  }

  [dir="rtl"] .m-application .pe-sm-8 {
    padding-left: 32px !important;
  }

  [dir="ltr"] .m-application .pe-sm-9 {
    padding-right: 36px !important;
  }

  [dir="rtl"] .m-application .pe-sm-9 {
    padding-left: 36px !important;
  }

  [dir="ltr"] .m-application .pe-sm-10 {
    padding-right: 40px !important;
  }

  [dir="rtl"] .m-application .pe-sm-10 {
    padding-left: 40px !important;
  }

  [dir="ltr"] .m-application .pe-sm-11 {
    padding-right: 44px !important;
  }

  [dir="rtl"] .m-application .pe-sm-11 {
    padding-left: 44px !important;
  }

  [dir="ltr"] .m-application .pe-sm-12 {
    padding-right: 48px !important;
  }

  [dir="rtl"] .m-application .pe-sm-12 {
    padding-left: 48px !important;
  }

  .m-application .text-sm-left {
    text-align: left !important;
  }

  .m-application .text-sm-right {
    text-align: right !important;
  }

  .m-application .text-sm-center {
    text-align: center !important;
  }

  .m-application .text-sm-justify {
    text-align: justify !important;
  }

  .m-application .text-sm-start {
    text-align: start !important;
  }

  .m-application .text-sm-end {
    text-align: end !important;
  }
}

@media (min-width: 960px) {
  .m-application .ma-md-0 {
    margin: 0px !important;
  }

  .m-application .ma-md-1 {
    margin: 4px !important;
  }

  .m-application .ma-md-2 {
    margin: 8px !important;
  }

  .m-application .ma-md-3 {
    margin: 12px !important;
  }

  .m-application .ma-md-4 {
    margin: 16px !important;
  }

  .m-application .ma-md-5 {
    margin: 20px !important;
  }

  .m-application .ma-md-6 {
    margin: 24px !important;
  }

  .m-application .ma-md-7 {
    margin: 28px !important;
  }

  .m-application .ma-md-8 {
    margin: 32px !important;
  }

  .m-application .ma-md-9 {
    margin: 36px !important;
  }

  .m-application .ma-md-10 {
    margin: 40px !important;
  }

  .m-application .ma-md-11 {
    margin: 44px !important;
  }

  .m-application .ma-md-12 {
    margin: 48px !important;
  }

  .m-application .ma-md-auto {
    margin: auto !important;
  }

  .m-application .mx-md-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .m-application .mx-md-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .m-application .mx-md-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .m-application .mx-md-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .m-application .mx-md-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .m-application .mx-md-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .m-application .mx-md-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .m-application .mx-md-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }

  .m-application .mx-md-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .m-application .mx-md-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .m-application .mx-md-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .m-application .mx-md-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }

  .m-application .mx-md-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .m-application .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .m-application .my-md-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .m-application .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .m-application .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .m-application .my-md-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .m-application .my-md-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .m-application .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-application .my-md-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .m-application .my-md-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .m-application .my-md-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .m-application .my-md-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .m-application .my-md-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-application .my-md-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .m-application .my-md-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .m-application .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-application .mt-md-0 {
    margin-top: 0px !important;
  }

  .m-application .mt-md-1 {
    margin-top: 4px !important;
  }

  .m-application .mt-md-2 {
    margin-top: 8px !important;
  }

  .m-application .mt-md-3 {
    margin-top: 12px !important;
  }

  .m-application .mt-md-4 {
    margin-top: 16px !important;
  }

  .m-application .mt-md-5 {
    margin-top: 20px !important;
  }

  .m-application .mt-md-6 {
    margin-top: 24px !important;
  }

  .m-application .mt-md-7 {
    margin-top: 28px !important;
  }

  .m-application .mt-md-8 {
    margin-top: 32px !important;
  }

  .m-application .mt-md-9 {
    margin-top: 36px !important;
  }

  .m-application .mt-md-10 {
    margin-top: 40px !important;
  }

  .m-application .mt-md-11 {
    margin-top: 44px !important;
  }

  .m-application .mt-md-12 {
    margin-top: 48px !important;
  }

  .m-application .mt-md-auto {
    margin-top: auto !important;
  }

  .m-application .mr-md-0 {
    margin-right: 0px !important;
  }

  .m-application .mr-md-1 {
    margin-right: 4px !important;
  }

  .m-application .mr-md-2 {
    margin-right: 8px !important;
  }

  .m-application .mr-md-3 {
    margin-right: 12px !important;
  }

  .m-application .mr-md-4 {
    margin-right: 16px !important;
  }

  .m-application .mr-md-5 {
    margin-right: 20px !important;
  }

  .m-application .mr-md-6 {
    margin-right: 24px !important;
  }

  .m-application .mr-md-7 {
    margin-right: 28px !important;
  }

  .m-application .mr-md-8 {
    margin-right: 32px !important;
  }

  .m-application .mr-md-9 {
    margin-right: 36px !important;
  }

  .m-application .mr-md-10 {
    margin-right: 40px !important;
  }

  .m-application .mr-md-11 {
    margin-right: 44px !important;
  }

  .m-application .mr-md-12 {
    margin-right: 48px !important;
  }

  .m-application .mr-md-auto {
    margin-right: auto !important;
  }

  .m-application .mb-md-0 {
    margin-bottom: 0px !important;
  }

  .m-application .mb-md-1 {
    margin-bottom: 4px !important;
  }

  .m-application .mb-md-2 {
    margin-bottom: 8px !important;
  }

  .m-application .mb-md-3 {
    margin-bottom: 12px !important;
  }

  .m-application .mb-md-4 {
    margin-bottom: 16px !important;
  }

  .m-application .mb-md-5 {
    margin-bottom: 20px !important;
  }

  .m-application .mb-md-6 {
    margin-bottom: 24px !important;
  }

  .m-application .mb-md-7 {
    margin-bottom: 28px !important;
  }

  .m-application .mb-md-8 {
    margin-bottom: 32px !important;
  }

  .m-application .mb-md-9 {
    margin-bottom: 36px !important;
  }

  .m-application .mb-md-10 {
    margin-bottom: 40px !important;
  }

  .m-application .mb-md-11 {
    margin-bottom: 44px !important;
  }

  .m-application .mb-md-12 {
    margin-bottom: 48px !important;
  }

  .m-application .mb-md-auto {
    margin-bottom: auto !important;
  }

  .m-application .ml-md-0 {
    margin-left: 0px !important;
  }

  .m-application .ml-md-1 {
    margin-left: 4px !important;
  }

  .m-application .ml-md-2 {
    margin-left: 8px !important;
  }

  .m-application .ml-md-3 {
    margin-left: 12px !important;
  }

  .m-application .ml-md-4 {
    margin-left: 16px !important;
  }

  .m-application .ml-md-5 {
    margin-left: 20px !important;
  }

  .m-application .ml-md-6 {
    margin-left: 24px !important;
  }

  .m-application .ml-md-7 {
    margin-left: 28px !important;
  }

  .m-application .ml-md-8 {
    margin-left: 32px !important;
  }

  .m-application .ml-md-9 {
    margin-left: 36px !important;
  }

  .m-application .ml-md-10 {
    margin-left: 40px !important;
  }

  .m-application .ml-md-11 {
    margin-left: 44px !important;
  }

  .m-application .ml-md-12 {
    margin-left: 48px !important;
  }

  .m-application .ml-md-auto {
    margin-left: auto !important;
  }

  [dir="ltr"] .m-application .ms-md-0 {
    margin-left: 0px !important;
  }

  [dir="rtl"] .m-application .ms-md-0 {
    margin-right: 0px !important;
  }

  [dir="ltr"] .m-application .ms-md-1 {
    margin-left: 4px !important;
  }

  [dir="rtl"] .m-application .ms-md-1 {
    margin-right: 4px !important;
  }

  [dir="ltr"] .m-application .ms-md-2 {
    margin-left: 8px !important;
  }

  [dir="rtl"] .m-application .ms-md-2 {
    margin-right: 8px !important;
  }

  [dir="ltr"] .m-application .ms-md-3 {
    margin-left: 12px !important;
  }

  [dir="rtl"] .m-application .ms-md-3 {
    margin-right: 12px !important;
  }

  [dir="ltr"] .m-application .ms-md-4 {
    margin-left: 16px !important;
  }

  [dir="rtl"] .m-application .ms-md-4 {
    margin-right: 16px !important;
  }

  [dir="ltr"] .m-application .ms-md-5 {
    margin-left: 20px !important;
  }

  [dir="rtl"] .m-application .ms-md-5 {
    margin-right: 20px !important;
  }

  [dir="ltr"] .m-application .ms-md-6 {
    margin-left: 24px !important;
  }

  [dir="rtl"] .m-application .ms-md-6 {
    margin-right: 24px !important;
  }

  [dir="ltr"] .m-application .ms-md-7 {
    margin-left: 28px !important;
  }

  [dir="rtl"] .m-application .ms-md-7 {
    margin-right: 28px !important;
  }

  [dir="ltr"] .m-application .ms-md-8 {
    margin-left: 32px !important;
  }

  [dir="rtl"] .m-application .ms-md-8 {
    margin-right: 32px !important;
  }

  [dir="ltr"] .m-application .ms-md-9 {
    margin-left: 36px !important;
  }

  [dir="rtl"] .m-application .ms-md-9 {
    margin-right: 36px !important;
  }

  [dir="ltr"] .m-application .ms-md-10 {
    margin-left: 40px !important;
  }

  [dir="rtl"] .m-application .ms-md-10 {
    margin-right: 40px !important;
  }

  [dir="ltr"] .m-application .ms-md-11 {
    margin-left: 44px !important;
  }

  [dir="rtl"] .m-application .ms-md-11 {
    margin-right: 44px !important;
  }

  [dir="ltr"] .m-application .ms-md-12 {
    margin-left: 48px !important;
  }

  [dir="rtl"] .m-application .ms-md-12 {
    margin-right: 48px !important;
  }

  [dir="ltr"] .m-application .ms-md-auto {
    margin-left: auto !important;
  }

  [dir="rtl"] .m-application .ms-md-auto {
    margin-right: auto !important;
  }

  [dir="ltr"] .m-application .me-md-0 {
    margin-right: 0px !important;
  }

  [dir="rtl"] .m-application .me-md-0 {
    margin-left: 0px !important;
  }

  [dir="ltr"] .m-application .me-md-1 {
    margin-right: 4px !important;
  }

  [dir="rtl"] .m-application .me-md-1 {
    margin-left: 4px !important;
  }

  [dir="ltr"] .m-application .me-md-2 {
    margin-right: 8px !important;
  }

  [dir="rtl"] .m-application .me-md-2 {
    margin-left: 8px !important;
  }

  [dir="ltr"] .m-application .me-md-3 {
    margin-right: 12px !important;
  }

  [dir="rtl"] .m-application .me-md-3 {
    margin-left: 12px !important;
  }

  [dir="ltr"] .m-application .me-md-4 {
    margin-right: 16px !important;
  }

  [dir="rtl"] .m-application .me-md-4 {
    margin-left: 16px !important;
  }

  [dir="ltr"] .m-application .me-md-5 {
    margin-right: 20px !important;
  }

  [dir="rtl"] .m-application .me-md-5 {
    margin-left: 20px !important;
  }

  [dir="ltr"] .m-application .me-md-6 {
    margin-right: 24px !important;
  }

  [dir="rtl"] .m-application .me-md-6 {
    margin-left: 24px !important;
  }

  [dir="ltr"] .m-application .me-md-7 {
    margin-right: 28px !important;
  }

  [dir="rtl"] .m-application .me-md-7 {
    margin-left: 28px !important;
  }

  [dir="ltr"] .m-application .me-md-8 {
    margin-right: 32px !important;
  }

  [dir="rtl"] .m-application .me-md-8 {
    margin-left: 32px !important;
  }

  [dir="ltr"] .m-application .me-md-9 {
    margin-right: 36px !important;
  }

  [dir="rtl"] .m-application .me-md-9 {
    margin-left: 36px !important;
  }

  [dir="ltr"] .m-application .me-md-10 {
    margin-right: 40px !important;
  }

  [dir="rtl"] .m-application .me-md-10 {
    margin-left: 40px !important;
  }

  [dir="ltr"] .m-application .me-md-11 {
    margin-right: 44px !important;
  }

  [dir="rtl"] .m-application .me-md-11 {
    margin-left: 44px !important;
  }

  [dir="ltr"] .m-application .me-md-12 {
    margin-right: 48px !important;
  }

  [dir="rtl"] .m-application .me-md-12 {
    margin-left: 48px !important;
  }

  [dir="ltr"] .m-application .me-md-auto {
    margin-right: auto !important;
  }

  [dir="rtl"] .m-application .me-md-auto {
    margin-left: auto !important;
  }

  .m-application .ma-md-n1 {
    margin: -4px !important;
  }

  .m-application .ma-md-n2 {
    margin: -8px !important;
  }

  .m-application .ma-md-n3 {
    margin: -12px !important;
  }

  .m-application .ma-md-n4 {
    margin: -16px !important;
  }

  .m-application .ma-md-n5 {
    margin: -20px !important;
  }

  .m-application .ma-md-n6 {
    margin: -24px !important;
  }

  .m-application .ma-md-n7 {
    margin: -28px !important;
  }

  .m-application .ma-md-n8 {
    margin: -32px !important;
  }

  .m-application .ma-md-n9 {
    margin: -36px !important;
  }

  .m-application .ma-md-n10 {
    margin: -40px !important;
  }

  .m-application .ma-md-n11 {
    margin: -44px !important;
  }

  .m-application .ma-md-n12 {
    margin: -48px !important;
  }

  .m-application .mx-md-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .m-application .mx-md-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .m-application .mx-md-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .m-application .mx-md-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .m-application .mx-md-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .m-application .mx-md-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .m-application .mx-md-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }

  .m-application .mx-md-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .m-application .mx-md-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .m-application .mx-md-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .m-application .mx-md-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }

  .m-application .mx-md-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .m-application .my-md-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .m-application .my-md-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .m-application .my-md-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .m-application .my-md-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .m-application .my-md-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m-application .my-md-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .m-application .my-md-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .m-application .my-md-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .m-application .my-md-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .m-application .my-md-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m-application .my-md-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .m-application .my-md-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .m-application .mt-md-n1 {
    margin-top: -4px !important;
  }

  .m-application .mt-md-n2 {
    margin-top: -8px !important;
  }

  .m-application .mt-md-n3 {
    margin-top: -12px !important;
  }

  .m-application .mt-md-n4 {
    margin-top: -16px !important;
  }

  .m-application .mt-md-n5 {
    margin-top: -20px !important;
  }

  .m-application .mt-md-n6 {
    margin-top: -24px !important;
  }

  .m-application .mt-md-n7 {
    margin-top: -28px !important;
  }

  .m-application .mt-md-n8 {
    margin-top: -32px !important;
  }

  .m-application .mt-md-n9 {
    margin-top: -36px !important;
  }

  .m-application .mt-md-n10 {
    margin-top: -40px !important;
  }

  .m-application .mt-md-n11 {
    margin-top: -44px !important;
  }

  .m-application .mt-md-n12 {
    margin-top: -48px !important;
  }

  .m-application .mr-md-n1 {
    margin-right: -4px !important;
  }

  .m-application .mr-md-n2 {
    margin-right: -8px !important;
  }

  .m-application .mr-md-n3 {
    margin-right: -12px !important;
  }

  .m-application .mr-md-n4 {
    margin-right: -16px !important;
  }

  .m-application .mr-md-n5 {
    margin-right: -20px !important;
  }

  .m-application .mr-md-n6 {
    margin-right: -24px !important;
  }

  .m-application .mr-md-n7 {
    margin-right: -28px !important;
  }

  .m-application .mr-md-n8 {
    margin-right: -32px !important;
  }

  .m-application .mr-md-n9 {
    margin-right: -36px !important;
  }

  .m-application .mr-md-n10 {
    margin-right: -40px !important;
  }

  .m-application .mr-md-n11 {
    margin-right: -44px !important;
  }

  .m-application .mr-md-n12 {
    margin-right: -48px !important;
  }

  .m-application .mb-md-n1 {
    margin-bottom: -4px !important;
  }

  .m-application .mb-md-n2 {
    margin-bottom: -8px !important;
  }

  .m-application .mb-md-n3 {
    margin-bottom: -12px !important;
  }

  .m-application .mb-md-n4 {
    margin-bottom: -16px !important;
  }

  .m-application .mb-md-n5 {
    margin-bottom: -20px !important;
  }

  .m-application .mb-md-n6 {
    margin-bottom: -24px !important;
  }

  .m-application .mb-md-n7 {
    margin-bottom: -28px !important;
  }

  .m-application .mb-md-n8 {
    margin-bottom: -32px !important;
  }

  .m-application .mb-md-n9 {
    margin-bottom: -36px !important;
  }

  .m-application .mb-md-n10 {
    margin-bottom: -40px !important;
  }

  .m-application .mb-md-n11 {
    margin-bottom: -44px !important;
  }

  .m-application .mb-md-n12 {
    margin-bottom: -48px !important;
  }

  .m-application .ml-md-n1 {
    margin-left: -4px !important;
  }

  .m-application .ml-md-n2 {
    margin-left: -8px !important;
  }

  .m-application .ml-md-n3 {
    margin-left: -12px !important;
  }

  .m-application .ml-md-n4 {
    margin-left: -16px !important;
  }

  .m-application .ml-md-n5 {
    margin-left: -20px !important;
  }

  .m-application .ml-md-n6 {
    margin-left: -24px !important;
  }

  .m-application .ml-md-n7 {
    margin-left: -28px !important;
  }

  .m-application .ml-md-n8 {
    margin-left: -32px !important;
  }

  .m-application .ml-md-n9 {
    margin-left: -36px !important;
  }

  .m-application .ml-md-n10 {
    margin-left: -40px !important;
  }

  .m-application .ml-md-n11 {
    margin-left: -44px !important;
  }

  .m-application .ml-md-n12 {
    margin-left: -48px !important;
  }

  [dir="ltr"] .m-application .ms-md-n1 {
    margin-left: -4px !important;
  }

  [dir="rtl"] .m-application .ms-md-n1 {
    margin-right: -4px !important;
  }

  [dir="ltr"] .m-application .ms-md-n2 {
    margin-left: -8px !important;
  }

  [dir="rtl"] .m-application .ms-md-n2 {
    margin-right: -8px !important;
  }

  [dir="ltr"] .m-application .ms-md-n3 {
    margin-left: -12px !important;
  }

  [dir="rtl"] .m-application .ms-md-n3 {
    margin-right: -12px !important;
  }

  [dir="ltr"] .m-application .ms-md-n4 {
    margin-left: -16px !important;
  }

  [dir="rtl"] .m-application .ms-md-n4 {
    margin-right: -16px !important;
  }

  [dir="ltr"] .m-application .ms-md-n5 {
    margin-left: -20px !important;
  }

  [dir="rtl"] .m-application .ms-md-n5 {
    margin-right: -20px !important;
  }

  [dir="ltr"] .m-application .ms-md-n6 {
    margin-left: -24px !important;
  }

  [dir="rtl"] .m-application .ms-md-n6 {
    margin-right: -24px !important;
  }

  [dir="ltr"] .m-application .ms-md-n7 {
    margin-left: -28px !important;
  }

  [dir="rtl"] .m-application .ms-md-n7 {
    margin-right: -28px !important;
  }

  [dir="ltr"] .m-application .ms-md-n8 {
    margin-left: -32px !important;
  }

  [dir="rtl"] .m-application .ms-md-n8 {
    margin-right: -32px !important;
  }

  [dir="ltr"] .m-application .ms-md-n9 {
    margin-left: -36px !important;
  }

  [dir="rtl"] .m-application .ms-md-n9 {
    margin-right: -36px !important;
  }

  [dir="ltr"] .m-application .ms-md-n10 {
    margin-left: -40px !important;
  }

  [dir="rtl"] .m-application .ms-md-n10 {
    margin-right: -40px !important;
  }

  [dir="ltr"] .m-application .ms-md-n11 {
    margin-left: -44px !important;
  }

  [dir="rtl"] .m-application .ms-md-n11 {
    margin-right: -44px !important;
  }

  [dir="ltr"] .m-application .ms-md-n12 {
    margin-left: -48px !important;
  }

  [dir="rtl"] .m-application .ms-md-n12 {
    margin-right: -48px !important;
  }

  [dir="ltr"] .m-application .me-md-n1 {
    margin-right: -4px !important;
  }

  [dir="rtl"] .m-application .me-md-n1 {
    margin-left: -4px !important;
  }

  [dir="ltr"] .m-application .me-md-n2 {
    margin-right: -8px !important;
  }

  [dir="rtl"] .m-application .me-md-n2 {
    margin-left: -8px !important;
  }

  [dir="ltr"] .m-application .me-md-n3 {
    margin-right: -12px !important;
  }

  [dir="rtl"] .m-application .me-md-n3 {
    margin-left: -12px !important;
  }

  [dir="ltr"] .m-application .me-md-n4 {
    margin-right: -16px !important;
  }

  [dir="rtl"] .m-application .me-md-n4 {
    margin-left: -16px !important;
  }

  [dir="ltr"] .m-application .me-md-n5 {
    margin-right: -20px !important;
  }

  [dir="rtl"] .m-application .me-md-n5 {
    margin-left: -20px !important;
  }

  [dir="ltr"] .m-application .me-md-n6 {
    margin-right: -24px !important;
  }

  [dir="rtl"] .m-application .me-md-n6 {
    margin-left: -24px !important;
  }

  [dir="ltr"] .m-application .me-md-n7 {
    margin-right: -28px !important;
  }

  [dir="rtl"] .m-application .me-md-n7 {
    margin-left: -28px !important;
  }

  [dir="ltr"] .m-application .me-md-n8 {
    margin-right: -32px !important;
  }

  [dir="rtl"] .m-application .me-md-n8 {
    margin-left: -32px !important;
  }

  [dir="ltr"] .m-application .me-md-n9 {
    margin-right: -36px !important;
  }

  [dir="rtl"] .m-application .me-md-n9 {
    margin-left: -36px !important;
  }

  [dir="ltr"] .m-application .me-md-n10 {
    margin-right: -40px !important;
  }

  [dir="rtl"] .m-application .me-md-n10 {
    margin-left: -40px !important;
  }

  [dir="ltr"] .m-application .me-md-n11 {
    margin-right: -44px !important;
  }

  [dir="rtl"] .m-application .me-md-n11 {
    margin-left: -44px !important;
  }

  [dir="ltr"] .m-application .me-md-n12 {
    margin-right: -48px !important;
  }

  [dir="rtl"] .m-application .me-md-n12 {
    margin-left: -48px !important;
  }

  .m-application .pa-md-0 {
    padding: 0px !important;
  }

  .m-application .pa-md-1 {
    padding: 4px !important;
  }

  .m-application .pa-md-2 {
    padding: 8px !important;
  }

  .m-application .pa-md-3 {
    padding: 12px !important;
  }

  .m-application .pa-md-4 {
    padding: 16px !important;
  }

  .m-application .pa-md-5 {
    padding: 20px !important;
  }

  .m-application .pa-md-6 {
    padding: 24px !important;
  }

  .m-application .pa-md-7 {
    padding: 28px !important;
  }

  .m-application .pa-md-8 {
    padding: 32px !important;
  }

  .m-application .pa-md-9 {
    padding: 36px !important;
  }

  .m-application .pa-md-10 {
    padding: 40px !important;
  }

  .m-application .pa-md-11 {
    padding: 44px !important;
  }

  .m-application .pa-md-12 {
    padding: 48px !important;
  }

  .m-application .px-md-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .m-application .px-md-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .m-application .px-md-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .m-application .px-md-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .m-application .px-md-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .m-application .px-md-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .m-application .px-md-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .m-application .px-md-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }

  .m-application .px-md-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .m-application .px-md-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .m-application .px-md-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .m-application .px-md-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }

  .m-application .px-md-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .m-application .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .m-application .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .m-application .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .m-application .py-md-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .m-application .py-md-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .m-application .py-md-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .m-application .py-md-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .m-application .py-md-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .m-application .py-md-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .m-application .py-md-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .m-application .py-md-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .m-application .py-md-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .m-application .py-md-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .m-application .pt-md-0 {
    padding-top: 0px !important;
  }

  .m-application .pt-md-1 {
    padding-top: 4px !important;
  }

  .m-application .pt-md-2 {
    padding-top: 8px !important;
  }

  .m-application .pt-md-3 {
    padding-top: 12px !important;
  }

  .m-application .pt-md-4 {
    padding-top: 16px !important;
  }

  .m-application .pt-md-5 {
    padding-top: 20px !important;
  }

  .m-application .pt-md-6 {
    padding-top: 24px !important;
  }

  .m-application .pt-md-7 {
    padding-top: 28px !important;
  }

  .m-application .pt-md-8 {
    padding-top: 32px !important;
  }

  .m-application .pt-md-9 {
    padding-top: 36px !important;
  }

  .m-application .pt-md-10 {
    padding-top: 40px !important;
  }

  .m-application .pt-md-11 {
    padding-top: 44px !important;
  }

  .m-application .pt-md-12 {
    padding-top: 48px !important;
  }

  .m-application .pr-md-0 {
    padding-right: 0px !important;
  }

  .m-application .pr-md-1 {
    padding-right: 4px !important;
  }

  .m-application .pr-md-2 {
    padding-right: 8px !important;
  }

  .m-application .pr-md-3 {
    padding-right: 12px !important;
  }

  .m-application .pr-md-4 {
    padding-right: 16px !important;
  }

  .m-application .pr-md-5 {
    padding-right: 20px !important;
  }

  .m-application .pr-md-6 {
    padding-right: 24px !important;
  }

  .m-application .pr-md-7 {
    padding-right: 28px !important;
  }

  .m-application .pr-md-8 {
    padding-right: 32px !important;
  }

  .m-application .pr-md-9 {
    padding-right: 36px !important;
  }

  .m-application .pr-md-10 {
    padding-right: 40px !important;
  }

  .m-application .pr-md-11 {
    padding-right: 44px !important;
  }

  .m-application .pr-md-12 {
    padding-right: 48px !important;
  }

  .m-application .pb-md-0 {
    padding-bottom: 0px !important;
  }

  .m-application .pb-md-1 {
    padding-bottom: 4px !important;
  }

  .m-application .pb-md-2 {
    padding-bottom: 8px !important;
  }

  .m-application .pb-md-3 {
    padding-bottom: 12px !important;
  }

  .m-application .pb-md-4 {
    padding-bottom: 16px !important;
  }

  .m-application .pb-md-5 {
    padding-bottom: 20px !important;
  }

  .m-application .pb-md-6 {
    padding-bottom: 24px !important;
  }

  .m-application .pb-md-7 {
    padding-bottom: 28px !important;
  }

  .m-application .pb-md-8 {
    padding-bottom: 32px !important;
  }

  .m-application .pb-md-9 {
    padding-bottom: 36px !important;
  }

  .m-application .pb-md-10 {
    padding-bottom: 40px !important;
  }

  .m-application .pb-md-11 {
    padding-bottom: 44px !important;
  }

  .m-application .pb-md-12 {
    padding-bottom: 48px !important;
  }

  .m-application .pl-md-0 {
    padding-left: 0px !important;
  }

  .m-application .pl-md-1 {
    padding-left: 4px !important;
  }

  .m-application .pl-md-2 {
    padding-left: 8px !important;
  }

  .m-application .pl-md-3 {
    padding-left: 12px !important;
  }

  .m-application .pl-md-4 {
    padding-left: 16px !important;
  }

  .m-application .pl-md-5 {
    padding-left: 20px !important;
  }

  .m-application .pl-md-6 {
    padding-left: 24px !important;
  }

  .m-application .pl-md-7 {
    padding-left: 28px !important;
  }

  .m-application .pl-md-8 {
    padding-left: 32px !important;
  }

  .m-application .pl-md-9 {
    padding-left: 36px !important;
  }

  .m-application .pl-md-10 {
    padding-left: 40px !important;
  }

  .m-application .pl-md-11 {
    padding-left: 44px !important;
  }

  .m-application .pl-md-12 {
    padding-left: 48px !important;
  }

  [dir="ltr"] .m-application .ps-md-0 {
    padding-left: 0px !important;
  }

  [dir="rtl"] .m-application .ps-md-0 {
    padding-right: 0px !important;
  }

  [dir="ltr"] .m-application .ps-md-1 {
    padding-left: 4px !important;
  }

  [dir="rtl"] .m-application .ps-md-1 {
    padding-right: 4px !important;
  }

  [dir="ltr"] .m-application .ps-md-2 {
    padding-left: 8px !important;
  }

  [dir="rtl"] .m-application .ps-md-2 {
    padding-right: 8px !important;
  }

  [dir="ltr"] .m-application .ps-md-3 {
    padding-left: 12px !important;
  }

  [dir="rtl"] .m-application .ps-md-3 {
    padding-right: 12px !important;
  }

  [dir="ltr"] .m-application .ps-md-4 {
    padding-left: 16px !important;
  }

  [dir="rtl"] .m-application .ps-md-4 {
    padding-right: 16px !important;
  }

  [dir="ltr"] .m-application .ps-md-5 {
    padding-left: 20px !important;
  }

  [dir="rtl"] .m-application .ps-md-5 {
    padding-right: 20px !important;
  }

  [dir="ltr"] .m-application .ps-md-6 {
    padding-left: 24px !important;
  }

  [dir="rtl"] .m-application .ps-md-6 {
    padding-right: 24px !important;
  }

  [dir="ltr"] .m-application .ps-md-7 {
    padding-left: 28px !important;
  }

  [dir="rtl"] .m-application .ps-md-7 {
    padding-right: 28px !important;
  }

  [dir="ltr"] .m-application .ps-md-8 {
    padding-left: 32px !important;
  }

  [dir="rtl"] .m-application .ps-md-8 {
    padding-right: 32px !important;
  }

  [dir="ltr"] .m-application .ps-md-9 {
    padding-left: 36px !important;
  }

  [dir="rtl"] .m-application .ps-md-9 {
    padding-right: 36px !important;
  }

  [dir="ltr"] .m-application .ps-md-10 {
    padding-left: 40px !important;
  }

  [dir="rtl"] .m-application .ps-md-10 {
    padding-right: 40px !important;
  }

  [dir="ltr"] .m-application .ps-md-11 {
    padding-left: 44px !important;
  }

  [dir="rtl"] .m-application .ps-md-11 {
    padding-right: 44px !important;
  }

  [dir="ltr"] .m-application .ps-md-12 {
    padding-left: 48px !important;
  }

  [dir="rtl"] .m-application .ps-md-12 {
    padding-right: 48px !important;
  }

  [dir="ltr"] .m-application .pe-md-0 {
    padding-right: 0px !important;
  }

  [dir="rtl"] .m-application .pe-md-0 {
    padding-left: 0px !important;
  }

  [dir="ltr"] .m-application .pe-md-1 {
    padding-right: 4px !important;
  }

  [dir="rtl"] .m-application .pe-md-1 {
    padding-left: 4px !important;
  }

  [dir="ltr"] .m-application .pe-md-2 {
    padding-right: 8px !important;
  }

  [dir="rtl"] .m-application .pe-md-2 {
    padding-left: 8px !important;
  }

  [dir="ltr"] .m-application .pe-md-3 {
    padding-right: 12px !important;
  }

  [dir="rtl"] .m-application .pe-md-3 {
    padding-left: 12px !important;
  }

  [dir="ltr"] .m-application .pe-md-4 {
    padding-right: 16px !important;
  }

  [dir="rtl"] .m-application .pe-md-4 {
    padding-left: 16px !important;
  }

  [dir="ltr"] .m-application .pe-md-5 {
    padding-right: 20px !important;
  }

  [dir="rtl"] .m-application .pe-md-5 {
    padding-left: 20px !important;
  }

  [dir="ltr"] .m-application .pe-md-6 {
    padding-right: 24px !important;
  }

  [dir="rtl"] .m-application .pe-md-6 {
    padding-left: 24px !important;
  }

  [dir="ltr"] .m-application .pe-md-7 {
    padding-right: 28px !important;
  }

  [dir="rtl"] .m-application .pe-md-7 {
    padding-left: 28px !important;
  }

  [dir="ltr"] .m-application .pe-md-8 {
    padding-right: 32px !important;
  }

  [dir="rtl"] .m-application .pe-md-8 {
    padding-left: 32px !important;
  }

  [dir="ltr"] .m-application .pe-md-9 {
    padding-right: 36px !important;
  }

  [dir="rtl"] .m-application .pe-md-9 {
    padding-left: 36px !important;
  }

  [dir="ltr"] .m-application .pe-md-10 {
    padding-right: 40px !important;
  }

  [dir="rtl"] .m-application .pe-md-10 {
    padding-left: 40px !important;
  }

  [dir="ltr"] .m-application .pe-md-11 {
    padding-right: 44px !important;
  }

  [dir="rtl"] .m-application .pe-md-11 {
    padding-left: 44px !important;
  }

  [dir="ltr"] .m-application .pe-md-12 {
    padding-right: 48px !important;
  }

  [dir="rtl"] .m-application .pe-md-12 {
    padding-left: 48px !important;
  }

  .m-application .text-md-left {
    text-align: left !important;
  }

  .m-application .text-md-right {
    text-align: right !important;
  }

  .m-application .text-md-center {
    text-align: center !important;
  }

  .m-application .text-md-justify {
    text-align: justify !important;
  }

  .m-application .text-md-start {
    text-align: start !important;
  }

  .m-application .text-md-end {
    text-align: end !important;
  }
}

@media (min-width: 1264px) {
  .m-application .ma-lg-0 {
    margin: 0px !important;
  }

  .m-application .ma-lg-1 {
    margin: 4px !important;
  }

  .m-application .ma-lg-2 {
    margin: 8px !important;
  }

  .m-application .ma-lg-3 {
    margin: 12px !important;
  }

  .m-application .ma-lg-4 {
    margin: 16px !important;
  }

  .m-application .ma-lg-5 {
    margin: 20px !important;
  }

  .m-application .ma-lg-6 {
    margin: 24px !important;
  }

  .m-application .ma-lg-7 {
    margin: 28px !important;
  }

  .m-application .ma-lg-8 {
    margin: 32px !important;
  }

  .m-application .ma-lg-9 {
    margin: 36px !important;
  }

  .m-application .ma-lg-10 {
    margin: 40px !important;
  }

  .m-application .ma-lg-11 {
    margin: 44px !important;
  }

  .m-application .ma-lg-12 {
    margin: 48px !important;
  }

  .m-application .ma-lg-auto {
    margin: auto !important;
  }

  .m-application .mx-lg-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .m-application .mx-lg-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .m-application .mx-lg-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .m-application .mx-lg-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .m-application .mx-lg-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .m-application .mx-lg-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .m-application .mx-lg-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .m-application .mx-lg-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }

  .m-application .mx-lg-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .m-application .mx-lg-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .m-application .mx-lg-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .m-application .mx-lg-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }

  .m-application .mx-lg-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .m-application .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .m-application .my-lg-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .m-application .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .m-application .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .m-application .my-lg-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .m-application .my-lg-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .m-application .my-lg-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-application .my-lg-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .m-application .my-lg-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .m-application .my-lg-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .m-application .my-lg-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .m-application .my-lg-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-application .my-lg-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .m-application .my-lg-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .m-application .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-application .mt-lg-0 {
    margin-top: 0px !important;
  }

  .m-application .mt-lg-1 {
    margin-top: 4px !important;
  }

  .m-application .mt-lg-2 {
    margin-top: 8px !important;
  }

  .m-application .mt-lg-3 {
    margin-top: 12px !important;
  }

  .m-application .mt-lg-4 {
    margin-top: 16px !important;
  }

  .m-application .mt-lg-5 {
    margin-top: 20px !important;
  }

  .m-application .mt-lg-6 {
    margin-top: 24px !important;
  }

  .m-application .mt-lg-7 {
    margin-top: 28px !important;
  }

  .m-application .mt-lg-8 {
    margin-top: 32px !important;
  }

  .m-application .mt-lg-9 {
    margin-top: 36px !important;
  }

  .m-application .mt-lg-10 {
    margin-top: 40px !important;
  }

  .m-application .mt-lg-11 {
    margin-top: 44px !important;
  }

  .m-application .mt-lg-12 {
    margin-top: 48px !important;
  }

  .m-application .mt-lg-auto {
    margin-top: auto !important;
  }

  .m-application .mr-lg-0 {
    margin-right: 0px !important;
  }

  .m-application .mr-lg-1 {
    margin-right: 4px !important;
  }

  .m-application .mr-lg-2 {
    margin-right: 8px !important;
  }

  .m-application .mr-lg-3 {
    margin-right: 12px !important;
  }

  .m-application .mr-lg-4 {
    margin-right: 16px !important;
  }

  .m-application .mr-lg-5 {
    margin-right: 20px !important;
  }

  .m-application .mr-lg-6 {
    margin-right: 24px !important;
  }

  .m-application .mr-lg-7 {
    margin-right: 28px !important;
  }

  .m-application .mr-lg-8 {
    margin-right: 32px !important;
  }

  .m-application .mr-lg-9 {
    margin-right: 36px !important;
  }

  .m-application .mr-lg-10 {
    margin-right: 40px !important;
  }

  .m-application .mr-lg-11 {
    margin-right: 44px !important;
  }

  .m-application .mr-lg-12 {
    margin-right: 48px !important;
  }

  .m-application .mr-lg-auto {
    margin-right: auto !important;
  }

  .m-application .mb-lg-0 {
    margin-bottom: 0px !important;
  }

  .m-application .mb-lg-1 {
    margin-bottom: 4px !important;
  }

  .m-application .mb-lg-2 {
    margin-bottom: 8px !important;
  }

  .m-application .mb-lg-3 {
    margin-bottom: 12px !important;
  }

  .m-application .mb-lg-4 {
    margin-bottom: 16px !important;
  }

  .m-application .mb-lg-5 {
    margin-bottom: 20px !important;
  }

  .m-application .mb-lg-6 {
    margin-bottom: 24px !important;
  }

  .m-application .mb-lg-7 {
    margin-bottom: 28px !important;
  }

  .m-application .mb-lg-8 {
    margin-bottom: 32px !important;
  }

  .m-application .mb-lg-9 {
    margin-bottom: 36px !important;
  }

  .m-application .mb-lg-10 {
    margin-bottom: 40px !important;
  }

  .m-application .mb-lg-11 {
    margin-bottom: 44px !important;
  }

  .m-application .mb-lg-12 {
    margin-bottom: 48px !important;
  }

  .m-application .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .m-application .ml-lg-0 {
    margin-left: 0px !important;
  }

  .m-application .ml-lg-1 {
    margin-left: 4px !important;
  }

  .m-application .ml-lg-2 {
    margin-left: 8px !important;
  }

  .m-application .ml-lg-3 {
    margin-left: 12px !important;
  }

  .m-application .ml-lg-4 {
    margin-left: 16px !important;
  }

  .m-application .ml-lg-5 {
    margin-left: 20px !important;
  }

  .m-application .ml-lg-6 {
    margin-left: 24px !important;
  }

  .m-application .ml-lg-7 {
    margin-left: 28px !important;
  }

  .m-application .ml-lg-8 {
    margin-left: 32px !important;
  }

  .m-application .ml-lg-9 {
    margin-left: 36px !important;
  }

  .m-application .ml-lg-10 {
    margin-left: 40px !important;
  }

  .m-application .ml-lg-11 {
    margin-left: 44px !important;
  }

  .m-application .ml-lg-12 {
    margin-left: 48px !important;
  }

  .m-application .ml-lg-auto {
    margin-left: auto !important;
  }

  [dir="ltr"] .m-application .ms-lg-0 {
    margin-left: 0px !important;
  }

  [dir="rtl"] .m-application .ms-lg-0 {
    margin-right: 0px !important;
  }

  [dir="ltr"] .m-application .ms-lg-1 {
    margin-left: 4px !important;
  }

  [dir="rtl"] .m-application .ms-lg-1 {
    margin-right: 4px !important;
  }

  [dir="ltr"] .m-application .ms-lg-2 {
    margin-left: 8px !important;
  }

  [dir="rtl"] .m-application .ms-lg-2 {
    margin-right: 8px !important;
  }

  [dir="ltr"] .m-application .ms-lg-3 {
    margin-left: 12px !important;
  }

  [dir="rtl"] .m-application .ms-lg-3 {
    margin-right: 12px !important;
  }

  [dir="ltr"] .m-application .ms-lg-4 {
    margin-left: 16px !important;
  }

  [dir="rtl"] .m-application .ms-lg-4 {
    margin-right: 16px !important;
  }

  [dir="ltr"] .m-application .ms-lg-5 {
    margin-left: 20px !important;
  }

  [dir="rtl"] .m-application .ms-lg-5 {
    margin-right: 20px !important;
  }

  [dir="ltr"] .m-application .ms-lg-6 {
    margin-left: 24px !important;
  }

  [dir="rtl"] .m-application .ms-lg-6 {
    margin-right: 24px !important;
  }

  [dir="ltr"] .m-application .ms-lg-7 {
    margin-left: 28px !important;
  }

  [dir="rtl"] .m-application .ms-lg-7 {
    margin-right: 28px !important;
  }

  [dir="ltr"] .m-application .ms-lg-8 {
    margin-left: 32px !important;
  }

  [dir="rtl"] .m-application .ms-lg-8 {
    margin-right: 32px !important;
  }

  [dir="ltr"] .m-application .ms-lg-9 {
    margin-left: 36px !important;
  }

  [dir="rtl"] .m-application .ms-lg-9 {
    margin-right: 36px !important;
  }

  [dir="ltr"] .m-application .ms-lg-10 {
    margin-left: 40px !important;
  }

  [dir="rtl"] .m-application .ms-lg-10 {
    margin-right: 40px !important;
  }

  [dir="ltr"] .m-application .ms-lg-11 {
    margin-left: 44px !important;
  }

  [dir="rtl"] .m-application .ms-lg-11 {
    margin-right: 44px !important;
  }

  [dir="ltr"] .m-application .ms-lg-12 {
    margin-left: 48px !important;
  }

  [dir="rtl"] .m-application .ms-lg-12 {
    margin-right: 48px !important;
  }

  [dir="ltr"] .m-application .ms-lg-auto {
    margin-left: auto !important;
  }

  [dir="rtl"] .m-application .ms-lg-auto {
    margin-right: auto !important;
  }

  [dir="ltr"] .m-application .me-lg-0 {
    margin-right: 0px !important;
  }

  [dir="rtl"] .m-application .me-lg-0 {
    margin-left: 0px !important;
  }

  [dir="ltr"] .m-application .me-lg-1 {
    margin-right: 4px !important;
  }

  [dir="rtl"] .m-application .me-lg-1 {
    margin-left: 4px !important;
  }

  [dir="ltr"] .m-application .me-lg-2 {
    margin-right: 8px !important;
  }

  [dir="rtl"] .m-application .me-lg-2 {
    margin-left: 8px !important;
  }

  [dir="ltr"] .m-application .me-lg-3 {
    margin-right: 12px !important;
  }

  [dir="rtl"] .m-application .me-lg-3 {
    margin-left: 12px !important;
  }

  [dir="ltr"] .m-application .me-lg-4 {
    margin-right: 16px !important;
  }

  [dir="rtl"] .m-application .me-lg-4 {
    margin-left: 16px !important;
  }

  [dir="ltr"] .m-application .me-lg-5 {
    margin-right: 20px !important;
  }

  [dir="rtl"] .m-application .me-lg-5 {
    margin-left: 20px !important;
  }

  [dir="ltr"] .m-application .me-lg-6 {
    margin-right: 24px !important;
  }

  [dir="rtl"] .m-application .me-lg-6 {
    margin-left: 24px !important;
  }

  [dir="ltr"] .m-application .me-lg-7 {
    margin-right: 28px !important;
  }

  [dir="rtl"] .m-application .me-lg-7 {
    margin-left: 28px !important;
  }

  [dir="ltr"] .m-application .me-lg-8 {
    margin-right: 32px !important;
  }

  [dir="rtl"] .m-application .me-lg-8 {
    margin-left: 32px !important;
  }

  [dir="ltr"] .m-application .me-lg-9 {
    margin-right: 36px !important;
  }

  [dir="rtl"] .m-application .me-lg-9 {
    margin-left: 36px !important;
  }

  [dir="ltr"] .m-application .me-lg-10 {
    margin-right: 40px !important;
  }

  [dir="rtl"] .m-application .me-lg-10 {
    margin-left: 40px !important;
  }

  [dir="ltr"] .m-application .me-lg-11 {
    margin-right: 44px !important;
  }

  [dir="rtl"] .m-application .me-lg-11 {
    margin-left: 44px !important;
  }

  [dir="ltr"] .m-application .me-lg-12 {
    margin-right: 48px !important;
  }

  [dir="rtl"] .m-application .me-lg-12 {
    margin-left: 48px !important;
  }

  [dir="ltr"] .m-application .me-lg-auto {
    margin-right: auto !important;
  }

  [dir="rtl"] .m-application .me-lg-auto {
    margin-left: auto !important;
  }

  .m-application .ma-lg-n1 {
    margin: -4px !important;
  }

  .m-application .ma-lg-n2 {
    margin: -8px !important;
  }

  .m-application .ma-lg-n3 {
    margin: -12px !important;
  }

  .m-application .ma-lg-n4 {
    margin: -16px !important;
  }

  .m-application .ma-lg-n5 {
    margin: -20px !important;
  }

  .m-application .ma-lg-n6 {
    margin: -24px !important;
  }

  .m-application .ma-lg-n7 {
    margin: -28px !important;
  }

  .m-application .ma-lg-n8 {
    margin: -32px !important;
  }

  .m-application .ma-lg-n9 {
    margin: -36px !important;
  }

  .m-application .ma-lg-n10 {
    margin: -40px !important;
  }

  .m-application .ma-lg-n11 {
    margin: -44px !important;
  }

  .m-application .ma-lg-n12 {
    margin: -48px !important;
  }

  .m-application .mx-lg-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .m-application .mx-lg-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .m-application .mx-lg-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .m-application .mx-lg-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .m-application .mx-lg-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .m-application .mx-lg-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .m-application .mx-lg-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }

  .m-application .mx-lg-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .m-application .mx-lg-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .m-application .mx-lg-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .m-application .mx-lg-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }

  .m-application .mx-lg-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .m-application .my-lg-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .m-application .my-lg-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .m-application .my-lg-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .m-application .my-lg-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .m-application .my-lg-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m-application .my-lg-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .m-application .my-lg-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .m-application .my-lg-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .m-application .my-lg-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .m-application .my-lg-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m-application .my-lg-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .m-application .my-lg-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .m-application .mt-lg-n1 {
    margin-top: -4px !important;
  }

  .m-application .mt-lg-n2 {
    margin-top: -8px !important;
  }

  .m-application .mt-lg-n3 {
    margin-top: -12px !important;
  }

  .m-application .mt-lg-n4 {
    margin-top: -16px !important;
  }

  .m-application .mt-lg-n5 {
    margin-top: -20px !important;
  }

  .m-application .mt-lg-n6 {
    margin-top: -24px !important;
  }

  .m-application .mt-lg-n7 {
    margin-top: -28px !important;
  }

  .m-application .mt-lg-n8 {
    margin-top: -32px !important;
  }

  .m-application .mt-lg-n9 {
    margin-top: -36px !important;
  }

  .m-application .mt-lg-n10 {
    margin-top: -40px !important;
  }

  .m-application .mt-lg-n11 {
    margin-top: -44px !important;
  }

  .m-application .mt-lg-n12 {
    margin-top: -48px !important;
  }

  .m-application .mr-lg-n1 {
    margin-right: -4px !important;
  }

  .m-application .mr-lg-n2 {
    margin-right: -8px !important;
  }

  .m-application .mr-lg-n3 {
    margin-right: -12px !important;
  }

  .m-application .mr-lg-n4 {
    margin-right: -16px !important;
  }

  .m-application .mr-lg-n5 {
    margin-right: -20px !important;
  }

  .m-application .mr-lg-n6 {
    margin-right: -24px !important;
  }

  .m-application .mr-lg-n7 {
    margin-right: -28px !important;
  }

  .m-application .mr-lg-n8 {
    margin-right: -32px !important;
  }

  .m-application .mr-lg-n9 {
    margin-right: -36px !important;
  }

  .m-application .mr-lg-n10 {
    margin-right: -40px !important;
  }

  .m-application .mr-lg-n11 {
    margin-right: -44px !important;
  }

  .m-application .mr-lg-n12 {
    margin-right: -48px !important;
  }

  .m-application .mb-lg-n1 {
    margin-bottom: -4px !important;
  }

  .m-application .mb-lg-n2 {
    margin-bottom: -8px !important;
  }

  .m-application .mb-lg-n3 {
    margin-bottom: -12px !important;
  }

  .m-application .mb-lg-n4 {
    margin-bottom: -16px !important;
  }

  .m-application .mb-lg-n5 {
    margin-bottom: -20px !important;
  }

  .m-application .mb-lg-n6 {
    margin-bottom: -24px !important;
  }

  .m-application .mb-lg-n7 {
    margin-bottom: -28px !important;
  }

  .m-application .mb-lg-n8 {
    margin-bottom: -32px !important;
  }

  .m-application .mb-lg-n9 {
    margin-bottom: -36px !important;
  }

  .m-application .mb-lg-n10 {
    margin-bottom: -40px !important;
  }

  .m-application .mb-lg-n11 {
    margin-bottom: -44px !important;
  }

  .m-application .mb-lg-n12 {
    margin-bottom: -48px !important;
  }

  .m-application .ml-lg-n1 {
    margin-left: -4px !important;
  }

  .m-application .ml-lg-n2 {
    margin-left: -8px !important;
  }

  .m-application .ml-lg-n3 {
    margin-left: -12px !important;
  }

  .m-application .ml-lg-n4 {
    margin-left: -16px !important;
  }

  .m-application .ml-lg-n5 {
    margin-left: -20px !important;
  }

  .m-application .ml-lg-n6 {
    margin-left: -24px !important;
  }

  .m-application .ml-lg-n7 {
    margin-left: -28px !important;
  }

  .m-application .ml-lg-n8 {
    margin-left: -32px !important;
  }

  .m-application .ml-lg-n9 {
    margin-left: -36px !important;
  }

  .m-application .ml-lg-n10 {
    margin-left: -40px !important;
  }

  .m-application .ml-lg-n11 {
    margin-left: -44px !important;
  }

  .m-application .ml-lg-n12 {
    margin-left: -48px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n1 {
    margin-left: -4px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n1 {
    margin-right: -4px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n2 {
    margin-left: -8px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n2 {
    margin-right: -8px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n3 {
    margin-left: -12px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n3 {
    margin-right: -12px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n4 {
    margin-left: -16px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n4 {
    margin-right: -16px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n5 {
    margin-left: -20px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n5 {
    margin-right: -20px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n6 {
    margin-left: -24px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n6 {
    margin-right: -24px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n7 {
    margin-left: -28px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n7 {
    margin-right: -28px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n8 {
    margin-left: -32px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n8 {
    margin-right: -32px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n9 {
    margin-left: -36px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n9 {
    margin-right: -36px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n10 {
    margin-left: -40px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n10 {
    margin-right: -40px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n11 {
    margin-left: -44px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n11 {
    margin-right: -44px !important;
  }

  [dir="ltr"] .m-application .ms-lg-n12 {
    margin-left: -48px !important;
  }

  [dir="rtl"] .m-application .ms-lg-n12 {
    margin-right: -48px !important;
  }

  [dir="ltr"] .m-application .me-lg-n1 {
    margin-right: -4px !important;
  }

  [dir="rtl"] .m-application .me-lg-n1 {
    margin-left: -4px !important;
  }

  [dir="ltr"] .m-application .me-lg-n2 {
    margin-right: -8px !important;
  }

  [dir="rtl"] .m-application .me-lg-n2 {
    margin-left: -8px !important;
  }

  [dir="ltr"] .m-application .me-lg-n3 {
    margin-right: -12px !important;
  }

  [dir="rtl"] .m-application .me-lg-n3 {
    margin-left: -12px !important;
  }

  [dir="ltr"] .m-application .me-lg-n4 {
    margin-right: -16px !important;
  }

  [dir="rtl"] .m-application .me-lg-n4 {
    margin-left: -16px !important;
  }

  [dir="ltr"] .m-application .me-lg-n5 {
    margin-right: -20px !important;
  }

  [dir="rtl"] .m-application .me-lg-n5 {
    margin-left: -20px !important;
  }

  [dir="ltr"] .m-application .me-lg-n6 {
    margin-right: -24px !important;
  }

  [dir="rtl"] .m-application .me-lg-n6 {
    margin-left: -24px !important;
  }

  [dir="ltr"] .m-application .me-lg-n7 {
    margin-right: -28px !important;
  }

  [dir="rtl"] .m-application .me-lg-n7 {
    margin-left: -28px !important;
  }

  [dir="ltr"] .m-application .me-lg-n8 {
    margin-right: -32px !important;
  }

  [dir="rtl"] .m-application .me-lg-n8 {
    margin-left: -32px !important;
  }

  [dir="ltr"] .m-application .me-lg-n9 {
    margin-right: -36px !important;
  }

  [dir="rtl"] .m-application .me-lg-n9 {
    margin-left: -36px !important;
  }

  [dir="ltr"] .m-application .me-lg-n10 {
    margin-right: -40px !important;
  }

  [dir="rtl"] .m-application .me-lg-n10 {
    margin-left: -40px !important;
  }

  [dir="ltr"] .m-application .me-lg-n11 {
    margin-right: -44px !important;
  }

  [dir="rtl"] .m-application .me-lg-n11 {
    margin-left: -44px !important;
  }

  [dir="ltr"] .m-application .me-lg-n12 {
    margin-right: -48px !important;
  }

  [dir="rtl"] .m-application .me-lg-n12 {
    margin-left: -48px !important;
  }

  .m-application .pa-lg-0 {
    padding: 0px !important;
  }

  .m-application .pa-lg-1 {
    padding: 4px !important;
  }

  .m-application .pa-lg-2 {
    padding: 8px !important;
  }

  .m-application .pa-lg-3 {
    padding: 12px !important;
  }

  .m-application .pa-lg-4 {
    padding: 16px !important;
  }

  .m-application .pa-lg-5 {
    padding: 20px !important;
  }

  .m-application .pa-lg-6 {
    padding: 24px !important;
  }

  .m-application .pa-lg-7 {
    padding: 28px !important;
  }

  .m-application .pa-lg-8 {
    padding: 32px !important;
  }

  .m-application .pa-lg-9 {
    padding: 36px !important;
  }

  .m-application .pa-lg-10 {
    padding: 40px !important;
  }

  .m-application .pa-lg-11 {
    padding: 44px !important;
  }

  .m-application .pa-lg-12 {
    padding: 48px !important;
  }

  .m-application .px-lg-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .m-application .px-lg-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .m-application .px-lg-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .m-application .px-lg-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .m-application .px-lg-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .m-application .px-lg-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .m-application .px-lg-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .m-application .px-lg-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }

  .m-application .px-lg-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .m-application .px-lg-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .m-application .px-lg-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .m-application .px-lg-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }

  .m-application .px-lg-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .m-application .py-lg-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .m-application .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .m-application .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .m-application .py-lg-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .m-application .py-lg-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .m-application .py-lg-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .m-application .py-lg-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .m-application .py-lg-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .m-application .py-lg-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .m-application .py-lg-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .m-application .py-lg-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .m-application .py-lg-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .m-application .py-lg-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .m-application .pt-lg-0 {
    padding-top: 0px !important;
  }

  .m-application .pt-lg-1 {
    padding-top: 4px !important;
  }

  .m-application .pt-lg-2 {
    padding-top: 8px !important;
  }

  .m-application .pt-lg-3 {
    padding-top: 12px !important;
  }

  .m-application .pt-lg-4 {
    padding-top: 16px !important;
  }

  .m-application .pt-lg-5 {
    padding-top: 20px !important;
  }

  .m-application .pt-lg-6 {
    padding-top: 24px !important;
  }

  .m-application .pt-lg-7 {
    padding-top: 28px !important;
  }

  .m-application .pt-lg-8 {
    padding-top: 32px !important;
  }

  .m-application .pt-lg-9 {
    padding-top: 36px !important;
  }

  .m-application .pt-lg-10 {
    padding-top: 40px !important;
  }

  .m-application .pt-lg-11 {
    padding-top: 44px !important;
  }

  .m-application .pt-lg-12 {
    padding-top: 48px !important;
  }

  .m-application .pr-lg-0 {
    padding-right: 0px !important;
  }

  .m-application .pr-lg-1 {
    padding-right: 4px !important;
  }

  .m-application .pr-lg-2 {
    padding-right: 8px !important;
  }

  .m-application .pr-lg-3 {
    padding-right: 12px !important;
  }

  .m-application .pr-lg-4 {
    padding-right: 16px !important;
  }

  .m-application .pr-lg-5 {
    padding-right: 20px !important;
  }

  .m-application .pr-lg-6 {
    padding-right: 24px !important;
  }

  .m-application .pr-lg-7 {
    padding-right: 28px !important;
  }

  .m-application .pr-lg-8 {
    padding-right: 32px !important;
  }

  .m-application .pr-lg-9 {
    padding-right: 36px !important;
  }

  .m-application .pr-lg-10 {
    padding-right: 40px !important;
  }

  .m-application .pr-lg-11 {
    padding-right: 44px !important;
  }

  .m-application .pr-lg-12 {
    padding-right: 48px !important;
  }

  .m-application .pb-lg-0 {
    padding-bottom: 0px !important;
  }

  .m-application .pb-lg-1 {
    padding-bottom: 4px !important;
  }

  .m-application .pb-lg-2 {
    padding-bottom: 8px !important;
  }

  .m-application .pb-lg-3 {
    padding-bottom: 12px !important;
  }

  .m-application .pb-lg-4 {
    padding-bottom: 16px !important;
  }

  .m-application .pb-lg-5 {
    padding-bottom: 20px !important;
  }

  .m-application .pb-lg-6 {
    padding-bottom: 24px !important;
  }

  .m-application .pb-lg-7 {
    padding-bottom: 28px !important;
  }

  .m-application .pb-lg-8 {
    padding-bottom: 32px !important;
  }

  .m-application .pb-lg-9 {
    padding-bottom: 36px !important;
  }

  .m-application .pb-lg-10 {
    padding-bottom: 40px !important;
  }

  .m-application .pb-lg-11 {
    padding-bottom: 44px !important;
  }

  .m-application .pb-lg-12 {
    padding-bottom: 48px !important;
  }

  .m-application .pl-lg-0 {
    padding-left: 0px !important;
  }

  .m-application .pl-lg-1 {
    padding-left: 4px !important;
  }

  .m-application .pl-lg-2 {
    padding-left: 8px !important;
  }

  .m-application .pl-lg-3 {
    padding-left: 12px !important;
  }

  .m-application .pl-lg-4 {
    padding-left: 16px !important;
  }

  .m-application .pl-lg-5 {
    padding-left: 20px !important;
  }

  .m-application .pl-lg-6 {
    padding-left: 24px !important;
  }

  .m-application .pl-lg-7 {
    padding-left: 28px !important;
  }

  .m-application .pl-lg-8 {
    padding-left: 32px !important;
  }

  .m-application .pl-lg-9 {
    padding-left: 36px !important;
  }

  .m-application .pl-lg-10 {
    padding-left: 40px !important;
  }

  .m-application .pl-lg-11 {
    padding-left: 44px !important;
  }

  .m-application .pl-lg-12 {
    padding-left: 48px !important;
  }

  [dir="ltr"] .m-application .ps-lg-0 {
    padding-left: 0px !important;
  }

  [dir="rtl"] .m-application .ps-lg-0 {
    padding-right: 0px !important;
  }

  [dir="ltr"] .m-application .ps-lg-1 {
    padding-left: 4px !important;
  }

  [dir="rtl"] .m-application .ps-lg-1 {
    padding-right: 4px !important;
  }

  [dir="ltr"] .m-application .ps-lg-2 {
    padding-left: 8px !important;
  }

  [dir="rtl"] .m-application .ps-lg-2 {
    padding-right: 8px !important;
  }

  [dir="ltr"] .m-application .ps-lg-3 {
    padding-left: 12px !important;
  }

  [dir="rtl"] .m-application .ps-lg-3 {
    padding-right: 12px !important;
  }

  [dir="ltr"] .m-application .ps-lg-4 {
    padding-left: 16px !important;
  }

  [dir="rtl"] .m-application .ps-lg-4 {
    padding-right: 16px !important;
  }

  [dir="ltr"] .m-application .ps-lg-5 {
    padding-left: 20px !important;
  }

  [dir="rtl"] .m-application .ps-lg-5 {
    padding-right: 20px !important;
  }

  [dir="ltr"] .m-application .ps-lg-6 {
    padding-left: 24px !important;
  }

  [dir="rtl"] .m-application .ps-lg-6 {
    padding-right: 24px !important;
  }

  [dir="ltr"] .m-application .ps-lg-7 {
    padding-left: 28px !important;
  }

  [dir="rtl"] .m-application .ps-lg-7 {
    padding-right: 28px !important;
  }

  [dir="ltr"] .m-application .ps-lg-8 {
    padding-left: 32px !important;
  }

  [dir="rtl"] .m-application .ps-lg-8 {
    padding-right: 32px !important;
  }

  [dir="ltr"] .m-application .ps-lg-9 {
    padding-left: 36px !important;
  }

  [dir="rtl"] .m-application .ps-lg-9 {
    padding-right: 36px !important;
  }

  [dir="ltr"] .m-application .ps-lg-10 {
    padding-left: 40px !important;
  }

  [dir="rtl"] .m-application .ps-lg-10 {
    padding-right: 40px !important;
  }

  [dir="ltr"] .m-application .ps-lg-11 {
    padding-left: 44px !important;
  }

  [dir="rtl"] .m-application .ps-lg-11 {
    padding-right: 44px !important;
  }

  [dir="ltr"] .m-application .ps-lg-12 {
    padding-left: 48px !important;
  }

  [dir="rtl"] .m-application .ps-lg-12 {
    padding-right: 48px !important;
  }

  [dir="ltr"] .m-application .pe-lg-0 {
    padding-right: 0px !important;
  }

  [dir="rtl"] .m-application .pe-lg-0 {
    padding-left: 0px !important;
  }

  [dir="ltr"] .m-application .pe-lg-1 {
    padding-right: 4px !important;
  }

  [dir="rtl"] .m-application .pe-lg-1 {
    padding-left: 4px !important;
  }

  [dir="ltr"] .m-application .pe-lg-2 {
    padding-right: 8px !important;
  }

  [dir="rtl"] .m-application .pe-lg-2 {
    padding-left: 8px !important;
  }

  [dir="ltr"] .m-application .pe-lg-3 {
    padding-right: 12px !important;
  }

  [dir="rtl"] .m-application .pe-lg-3 {
    padding-left: 12px !important;
  }

  [dir="ltr"] .m-application .pe-lg-4 {
    padding-right: 16px !important;
  }

  [dir="rtl"] .m-application .pe-lg-4 {
    padding-left: 16px !important;
  }

  [dir="ltr"] .m-application .pe-lg-5 {
    padding-right: 20px !important;
  }

  [dir="rtl"] .m-application .pe-lg-5 {
    padding-left: 20px !important;
  }

  [dir="ltr"] .m-application .pe-lg-6 {
    padding-right: 24px !important;
  }

  [dir="rtl"] .m-application .pe-lg-6 {
    padding-left: 24px !important;
  }

  [dir="ltr"] .m-application .pe-lg-7 {
    padding-right: 28px !important;
  }

  [dir="rtl"] .m-application .pe-lg-7 {
    padding-left: 28px !important;
  }

  [dir="ltr"] .m-application .pe-lg-8 {
    padding-right: 32px !important;
  }

  [dir="rtl"] .m-application .pe-lg-8 {
    padding-left: 32px !important;
  }

  [dir="ltr"] .m-application .pe-lg-9 {
    padding-right: 36px !important;
  }

  [dir="rtl"] .m-application .pe-lg-9 {
    padding-left: 36px !important;
  }

  [dir="ltr"] .m-application .pe-lg-10 {
    padding-right: 40px !important;
  }

  [dir="rtl"] .m-application .pe-lg-10 {
    padding-left: 40px !important;
  }

  [dir="ltr"] .m-application .pe-lg-11 {
    padding-right: 44px !important;
  }

  [dir="rtl"] .m-application .pe-lg-11 {
    padding-left: 44px !important;
  }

  [dir="ltr"] .m-application .pe-lg-12 {
    padding-right: 48px !important;
  }

  [dir="rtl"] .m-application .pe-lg-12 {
    padding-left: 48px !important;
  }

  .m-application .text-lg-left {
    text-align: left !important;
  }

  .m-application .text-lg-right {
    text-align: right !important;
  }

  .m-application .text-lg-center {
    text-align: center !important;
  }

  .m-application .text-lg-justify {
    text-align: justify !important;
  }

  .m-application .text-lg-start {
    text-align: start !important;
  }

  .m-application .text-lg-end {
    text-align: end !important;
  }
}

@media (min-width: 1904px) {
  .m-application .ma-xl-0 {
    margin: 0px !important;
  }

  .m-application .ma-xl-1 {
    margin: 4px !important;
  }

  .m-application .ma-xl-2 {
    margin: 8px !important;
  }

  .m-application .ma-xl-3 {
    margin: 12px !important;
  }

  .m-application .ma-xl-4 {
    margin: 16px !important;
  }

  .m-application .ma-xl-5 {
    margin: 20px !important;
  }

  .m-application .ma-xl-6 {
    margin: 24px !important;
  }

  .m-application .ma-xl-7 {
    margin: 28px !important;
  }

  .m-application .ma-xl-8 {
    margin: 32px !important;
  }

  .m-application .ma-xl-9 {
    margin: 36px !important;
  }

  .m-application .ma-xl-10 {
    margin: 40px !important;
  }

  .m-application .ma-xl-11 {
    margin: 44px !important;
  }

  .m-application .ma-xl-12 {
    margin: 48px !important;
  }

  .m-application .ma-xl-auto {
    margin: auto !important;
  }

  .m-application .mx-xl-0 {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .m-application .mx-xl-1 {
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  .m-application .mx-xl-2 {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }

  .m-application .mx-xl-3 {
    margin-right: 12px !important;
    margin-left: 12px !important;
  }

  .m-application .mx-xl-4 {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }

  .m-application .mx-xl-5 {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .m-application .mx-xl-6 {
    margin-right: 24px !important;
    margin-left: 24px !important;
  }

  .m-application .mx-xl-7 {
    margin-right: 28px !important;
    margin-left: 28px !important;
  }

  .m-application .mx-xl-8 {
    margin-right: 32px !important;
    margin-left: 32px !important;
  }

  .m-application .mx-xl-9 {
    margin-right: 36px !important;
    margin-left: 36px !important;
  }

  .m-application .mx-xl-10 {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .m-application .mx-xl-11 {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }

  .m-application .mx-xl-12 {
    margin-right: 48px !important;
    margin-left: 48px !important;
  }

  .m-application .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .m-application .my-xl-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .m-application .my-xl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .m-application .my-xl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .m-application .my-xl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .m-application .my-xl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .m-application .my-xl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .m-application .my-xl-6 {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .m-application .my-xl-7 {
    margin-top: 28px !important;
    margin-bottom: 28px !important;
  }

  .m-application .my-xl-8 {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .m-application .my-xl-9 {
    margin-top: 36px !important;
    margin-bottom: 36px !important;
  }

  .m-application .my-xl-10 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .m-application .my-xl-11 {
    margin-top: 44px !important;
    margin-bottom: 44px !important;
  }

  .m-application .my-xl-12 {
    margin-top: 48px !important;
    margin-bottom: 48px !important;
  }

  .m-application .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .m-application .mt-xl-0 {
    margin-top: 0px !important;
  }

  .m-application .mt-xl-1 {
    margin-top: 4px !important;
  }

  .m-application .mt-xl-2 {
    margin-top: 8px !important;
  }

  .m-application .mt-xl-3 {
    margin-top: 12px !important;
  }

  .m-application .mt-xl-4 {
    margin-top: 16px !important;
  }

  .m-application .mt-xl-5 {
    margin-top: 20px !important;
  }

  .m-application .mt-xl-6 {
    margin-top: 24px !important;
  }

  .m-application .mt-xl-7 {
    margin-top: 28px !important;
  }

  .m-application .mt-xl-8 {
    margin-top: 32px !important;
  }

  .m-application .mt-xl-9 {
    margin-top: 36px !important;
  }

  .m-application .mt-xl-10 {
    margin-top: 40px !important;
  }

  .m-application .mt-xl-11 {
    margin-top: 44px !important;
  }

  .m-application .mt-xl-12 {
    margin-top: 48px !important;
  }

  .m-application .mt-xl-auto {
    margin-top: auto !important;
  }

  .m-application .mr-xl-0 {
    margin-right: 0px !important;
  }

  .m-application .mr-xl-1 {
    margin-right: 4px !important;
  }

  .m-application .mr-xl-2 {
    margin-right: 8px !important;
  }

  .m-application .mr-xl-3 {
    margin-right: 12px !important;
  }

  .m-application .mr-xl-4 {
    margin-right: 16px !important;
  }

  .m-application .mr-xl-5 {
    margin-right: 20px !important;
  }

  .m-application .mr-xl-6 {
    margin-right: 24px !important;
  }

  .m-application .mr-xl-7 {
    margin-right: 28px !important;
  }

  .m-application .mr-xl-8 {
    margin-right: 32px !important;
  }

  .m-application .mr-xl-9 {
    margin-right: 36px !important;
  }

  .m-application .mr-xl-10 {
    margin-right: 40px !important;
  }

  .m-application .mr-xl-11 {
    margin-right: 44px !important;
  }

  .m-application .mr-xl-12 {
    margin-right: 48px !important;
  }

  .m-application .mr-xl-auto {
    margin-right: auto !important;
  }

  .m-application .mb-xl-0 {
    margin-bottom: 0px !important;
  }

  .m-application .mb-xl-1 {
    margin-bottom: 4px !important;
  }

  .m-application .mb-xl-2 {
    margin-bottom: 8px !important;
  }

  .m-application .mb-xl-3 {
    margin-bottom: 12px !important;
  }

  .m-application .mb-xl-4 {
    margin-bottom: 16px !important;
  }

  .m-application .mb-xl-5 {
    margin-bottom: 20px !important;
  }

  .m-application .mb-xl-6 {
    margin-bottom: 24px !important;
  }

  .m-application .mb-xl-7 {
    margin-bottom: 28px !important;
  }

  .m-application .mb-xl-8 {
    margin-bottom: 32px !important;
  }

  .m-application .mb-xl-9 {
    margin-bottom: 36px !important;
  }

  .m-application .mb-xl-10 {
    margin-bottom: 40px !important;
  }

  .m-application .mb-xl-11 {
    margin-bottom: 44px !important;
  }

  .m-application .mb-xl-12 {
    margin-bottom: 48px !important;
  }

  .m-application .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .m-application .ml-xl-0 {
    margin-left: 0px !important;
  }

  .m-application .ml-xl-1 {
    margin-left: 4px !important;
  }

  .m-application .ml-xl-2 {
    margin-left: 8px !important;
  }

  .m-application .ml-xl-3 {
    margin-left: 12px !important;
  }

  .m-application .ml-xl-4 {
    margin-left: 16px !important;
  }

  .m-application .ml-xl-5 {
    margin-left: 20px !important;
  }

  .m-application .ml-xl-6 {
    margin-left: 24px !important;
  }

  .m-application .ml-xl-7 {
    margin-left: 28px !important;
  }

  .m-application .ml-xl-8 {
    margin-left: 32px !important;
  }

  .m-application .ml-xl-9 {
    margin-left: 36px !important;
  }

  .m-application .ml-xl-10 {
    margin-left: 40px !important;
  }

  .m-application .ml-xl-11 {
    margin-left: 44px !important;
  }

  .m-application .ml-xl-12 {
    margin-left: 48px !important;
  }

  .m-application .ml-xl-auto {
    margin-left: auto !important;
  }

  [dir="ltr"] .m-application .ms-xl-0 {
    margin-left: 0px !important;
  }

  [dir="rtl"] .m-application .ms-xl-0 {
    margin-right: 0px !important;
  }

  [dir="ltr"] .m-application .ms-xl-1 {
    margin-left: 4px !important;
  }

  [dir="rtl"] .m-application .ms-xl-1 {
    margin-right: 4px !important;
  }

  [dir="ltr"] .m-application .ms-xl-2 {
    margin-left: 8px !important;
  }

  [dir="rtl"] .m-application .ms-xl-2 {
    margin-right: 8px !important;
  }

  [dir="ltr"] .m-application .ms-xl-3 {
    margin-left: 12px !important;
  }

  [dir="rtl"] .m-application .ms-xl-3 {
    margin-right: 12px !important;
  }

  [dir="ltr"] .m-application .ms-xl-4 {
    margin-left: 16px !important;
  }

  [dir="rtl"] .m-application .ms-xl-4 {
    margin-right: 16px !important;
  }

  [dir="ltr"] .m-application .ms-xl-5 {
    margin-left: 20px !important;
  }

  [dir="rtl"] .m-application .ms-xl-5 {
    margin-right: 20px !important;
  }

  [dir="ltr"] .m-application .ms-xl-6 {
    margin-left: 24px !important;
  }

  [dir="rtl"] .m-application .ms-xl-6 {
    margin-right: 24px !important;
  }

  [dir="ltr"] .m-application .ms-xl-7 {
    margin-left: 28px !important;
  }

  [dir="rtl"] .m-application .ms-xl-7 {
    margin-right: 28px !important;
  }

  [dir="ltr"] .m-application .ms-xl-8 {
    margin-left: 32px !important;
  }

  [dir="rtl"] .m-application .ms-xl-8 {
    margin-right: 32px !important;
  }

  [dir="ltr"] .m-application .ms-xl-9 {
    margin-left: 36px !important;
  }

  [dir="rtl"] .m-application .ms-xl-9 {
    margin-right: 36px !important;
  }

  [dir="ltr"] .m-application .ms-xl-10 {
    margin-left: 40px !important;
  }

  [dir="rtl"] .m-application .ms-xl-10 {
    margin-right: 40px !important;
  }

  [dir="ltr"] .m-application .ms-xl-11 {
    margin-left: 44px !important;
  }

  [dir="rtl"] .m-application .ms-xl-11 {
    margin-right: 44px !important;
  }

  [dir="ltr"] .m-application .ms-xl-12 {
    margin-left: 48px !important;
  }

  [dir="rtl"] .m-application .ms-xl-12 {
    margin-right: 48px !important;
  }

  [dir="ltr"] .m-application .ms-xl-auto {
    margin-left: auto !important;
  }

  [dir="rtl"] .m-application .ms-xl-auto {
    margin-right: auto !important;
  }

  [dir="ltr"] .m-application .me-xl-0 {
    margin-right: 0px !important;
  }

  [dir="rtl"] .m-application .me-xl-0 {
    margin-left: 0px !important;
  }

  [dir="ltr"] .m-application .me-xl-1 {
    margin-right: 4px !important;
  }

  [dir="rtl"] .m-application .me-xl-1 {
    margin-left: 4px !important;
  }

  [dir="ltr"] .m-application .me-xl-2 {
    margin-right: 8px !important;
  }

  [dir="rtl"] .m-application .me-xl-2 {
    margin-left: 8px !important;
  }

  [dir="ltr"] .m-application .me-xl-3 {
    margin-right: 12px !important;
  }

  [dir="rtl"] .m-application .me-xl-3 {
    margin-left: 12px !important;
  }

  [dir="ltr"] .m-application .me-xl-4 {
    margin-right: 16px !important;
  }

  [dir="rtl"] .m-application .me-xl-4 {
    margin-left: 16px !important;
  }

  [dir="ltr"] .m-application .me-xl-5 {
    margin-right: 20px !important;
  }

  [dir="rtl"] .m-application .me-xl-5 {
    margin-left: 20px !important;
  }

  [dir="ltr"] .m-application .me-xl-6 {
    margin-right: 24px !important;
  }

  [dir="rtl"] .m-application .me-xl-6 {
    margin-left: 24px !important;
  }

  [dir="ltr"] .m-application .me-xl-7 {
    margin-right: 28px !important;
  }

  [dir="rtl"] .m-application .me-xl-7 {
    margin-left: 28px !important;
  }

  [dir="ltr"] .m-application .me-xl-8 {
    margin-right: 32px !important;
  }

  [dir="rtl"] .m-application .me-xl-8 {
    margin-left: 32px !important;
  }

  [dir="ltr"] .m-application .me-xl-9 {
    margin-right: 36px !important;
  }

  [dir="rtl"] .m-application .me-xl-9 {
    margin-left: 36px !important;
  }

  [dir="ltr"] .m-application .me-xl-10 {
    margin-right: 40px !important;
  }

  [dir="rtl"] .m-application .me-xl-10 {
    margin-left: 40px !important;
  }

  [dir="ltr"] .m-application .me-xl-11 {
    margin-right: 44px !important;
  }

  [dir="rtl"] .m-application .me-xl-11 {
    margin-left: 44px !important;
  }

  [dir="ltr"] .m-application .me-xl-12 {
    margin-right: 48px !important;
  }

  [dir="rtl"] .m-application .me-xl-12 {
    margin-left: 48px !important;
  }

  [dir="ltr"] .m-application .me-xl-auto {
    margin-right: auto !important;
  }

  [dir="rtl"] .m-application .me-xl-auto {
    margin-left: auto !important;
  }

  .m-application .ma-xl-n1 {
    margin: -4px !important;
  }

  .m-application .ma-xl-n2 {
    margin: -8px !important;
  }

  .m-application .ma-xl-n3 {
    margin: -12px !important;
  }

  .m-application .ma-xl-n4 {
    margin: -16px !important;
  }

  .m-application .ma-xl-n5 {
    margin: -20px !important;
  }

  .m-application .ma-xl-n6 {
    margin: -24px !important;
  }

  .m-application .ma-xl-n7 {
    margin: -28px !important;
  }

  .m-application .ma-xl-n8 {
    margin: -32px !important;
  }

  .m-application .ma-xl-n9 {
    margin: -36px !important;
  }

  .m-application .ma-xl-n10 {
    margin: -40px !important;
  }

  .m-application .ma-xl-n11 {
    margin: -44px !important;
  }

  .m-application .ma-xl-n12 {
    margin: -48px !important;
  }

  .m-application .mx-xl-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important;
  }

  .m-application .mx-xl-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important;
  }

  .m-application .mx-xl-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important;
  }

  .m-application .mx-xl-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important;
  }

  .m-application .mx-xl-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important;
  }

  .m-application .mx-xl-n6 {
    margin-right: -24px !important;
    margin-left: -24px !important;
  }

  .m-application .mx-xl-n7 {
    margin-right: -28px !important;
    margin-left: -28px !important;
  }

  .m-application .mx-xl-n8 {
    margin-right: -32px !important;
    margin-left: -32px !important;
  }

  .m-application .mx-xl-n9 {
    margin-right: -36px !important;
    margin-left: -36px !important;
  }

  .m-application .mx-xl-n10 {
    margin-right: -40px !important;
    margin-left: -40px !important;
  }

  .m-application .mx-xl-n11 {
    margin-right: -44px !important;
    margin-left: -44px !important;
  }

  .m-application .mx-xl-n12 {
    margin-right: -48px !important;
    margin-left: -48px !important;
  }

  .m-application .my-xl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important;
  }

  .m-application .my-xl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }

  .m-application .my-xl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important;
  }

  .m-application .my-xl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important;
  }

  .m-application .my-xl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }

  .m-application .my-xl-n6 {
    margin-top: -24px !important;
    margin-bottom: -24px !important;
  }

  .m-application .my-xl-n7 {
    margin-top: -28px !important;
    margin-bottom: -28px !important;
  }

  .m-application .my-xl-n8 {
    margin-top: -32px !important;
    margin-bottom: -32px !important;
  }

  .m-application .my-xl-n9 {
    margin-top: -36px !important;
    margin-bottom: -36px !important;
  }

  .m-application .my-xl-n10 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }

  .m-application .my-xl-n11 {
    margin-top: -44px !important;
    margin-bottom: -44px !important;
  }

  .m-application .my-xl-n12 {
    margin-top: -48px !important;
    margin-bottom: -48px !important;
  }

  .m-application .mt-xl-n1 {
    margin-top: -4px !important;
  }

  .m-application .mt-xl-n2 {
    margin-top: -8px !important;
  }

  .m-application .mt-xl-n3 {
    margin-top: -12px !important;
  }

  .m-application .mt-xl-n4 {
    margin-top: -16px !important;
  }

  .m-application .mt-xl-n5 {
    margin-top: -20px !important;
  }

  .m-application .mt-xl-n6 {
    margin-top: -24px !important;
  }

  .m-application .mt-xl-n7 {
    margin-top: -28px !important;
  }

  .m-application .mt-xl-n8 {
    margin-top: -32px !important;
  }

  .m-application .mt-xl-n9 {
    margin-top: -36px !important;
  }

  .m-application .mt-xl-n10 {
    margin-top: -40px !important;
  }

  .m-application .mt-xl-n11 {
    margin-top: -44px !important;
  }

  .m-application .mt-xl-n12 {
    margin-top: -48px !important;
  }

  .m-application .mr-xl-n1 {
    margin-right: -4px !important;
  }

  .m-application .mr-xl-n2 {
    margin-right: -8px !important;
  }

  .m-application .mr-xl-n3 {
    margin-right: -12px !important;
  }

  .m-application .mr-xl-n4 {
    margin-right: -16px !important;
  }

  .m-application .mr-xl-n5 {
    margin-right: -20px !important;
  }

  .m-application .mr-xl-n6 {
    margin-right: -24px !important;
  }

  .m-application .mr-xl-n7 {
    margin-right: -28px !important;
  }

  .m-application .mr-xl-n8 {
    margin-right: -32px !important;
  }

  .m-application .mr-xl-n9 {
    margin-right: -36px !important;
  }

  .m-application .mr-xl-n10 {
    margin-right: -40px !important;
  }

  .m-application .mr-xl-n11 {
    margin-right: -44px !important;
  }

  .m-application .mr-xl-n12 {
    margin-right: -48px !important;
  }

  .m-application .mb-xl-n1 {
    margin-bottom: -4px !important;
  }

  .m-application .mb-xl-n2 {
    margin-bottom: -8px !important;
  }

  .m-application .mb-xl-n3 {
    margin-bottom: -12px !important;
  }

  .m-application .mb-xl-n4 {
    margin-bottom: -16px !important;
  }

  .m-application .mb-xl-n5 {
    margin-bottom: -20px !important;
  }

  .m-application .mb-xl-n6 {
    margin-bottom: -24px !important;
  }

  .m-application .mb-xl-n7 {
    margin-bottom: -28px !important;
  }

  .m-application .mb-xl-n8 {
    margin-bottom: -32px !important;
  }

  .m-application .mb-xl-n9 {
    margin-bottom: -36px !important;
  }

  .m-application .mb-xl-n10 {
    margin-bottom: -40px !important;
  }

  .m-application .mb-xl-n11 {
    margin-bottom: -44px !important;
  }

  .m-application .mb-xl-n12 {
    margin-bottom: -48px !important;
  }

  .m-application .ml-xl-n1 {
    margin-left: -4px !important;
  }

  .m-application .ml-xl-n2 {
    margin-left: -8px !important;
  }

  .m-application .ml-xl-n3 {
    margin-left: -12px !important;
  }

  .m-application .ml-xl-n4 {
    margin-left: -16px !important;
  }

  .m-application .ml-xl-n5 {
    margin-left: -20px !important;
  }

  .m-application .ml-xl-n6 {
    margin-left: -24px !important;
  }

  .m-application .ml-xl-n7 {
    margin-left: -28px !important;
  }

  .m-application .ml-xl-n8 {
    margin-left: -32px !important;
  }

  .m-application .ml-xl-n9 {
    margin-left: -36px !important;
  }

  .m-application .ml-xl-n10 {
    margin-left: -40px !important;
  }

  .m-application .ml-xl-n11 {
    margin-left: -44px !important;
  }

  .m-application .ml-xl-n12 {
    margin-left: -48px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n1 {
    margin-left: -4px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n1 {
    margin-right: -4px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n2 {
    margin-left: -8px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n2 {
    margin-right: -8px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n3 {
    margin-left: -12px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n3 {
    margin-right: -12px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n4 {
    margin-left: -16px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n4 {
    margin-right: -16px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n5 {
    margin-left: -20px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n5 {
    margin-right: -20px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n6 {
    margin-left: -24px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n6 {
    margin-right: -24px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n7 {
    margin-left: -28px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n7 {
    margin-right: -28px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n8 {
    margin-left: -32px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n8 {
    margin-right: -32px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n9 {
    margin-left: -36px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n9 {
    margin-right: -36px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n10 {
    margin-left: -40px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n10 {
    margin-right: -40px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n11 {
    margin-left: -44px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n11 {
    margin-right: -44px !important;
  }

  [dir="ltr"] .m-application .ms-xl-n12 {
    margin-left: -48px !important;
  }

  [dir="rtl"] .m-application .ms-xl-n12 {
    margin-right: -48px !important;
  }

  [dir="ltr"] .m-application .me-xl-n1 {
    margin-right: -4px !important;
  }

  [dir="rtl"] .m-application .me-xl-n1 {
    margin-left: -4px !important;
  }

  [dir="ltr"] .m-application .me-xl-n2 {
    margin-right: -8px !important;
  }

  [dir="rtl"] .m-application .me-xl-n2 {
    margin-left: -8px !important;
  }

  [dir="ltr"] .m-application .me-xl-n3 {
    margin-right: -12px !important;
  }

  [dir="rtl"] .m-application .me-xl-n3 {
    margin-left: -12px !important;
  }

  [dir="ltr"] .m-application .me-xl-n4 {
    margin-right: -16px !important;
  }

  [dir="rtl"] .m-application .me-xl-n4 {
    margin-left: -16px !important;
  }

  [dir="ltr"] .m-application .me-xl-n5 {
    margin-right: -20px !important;
  }

  [dir="rtl"] .m-application .me-xl-n5 {
    margin-left: -20px !important;
  }

  [dir="ltr"] .m-application .me-xl-n6 {
    margin-right: -24px !important;
  }

  [dir="rtl"] .m-application .me-xl-n6 {
    margin-left: -24px !important;
  }

  [dir="ltr"] .m-application .me-xl-n7 {
    margin-right: -28px !important;
  }

  [dir="rtl"] .m-application .me-xl-n7 {
    margin-left: -28px !important;
  }

  [dir="ltr"] .m-application .me-xl-n8 {
    margin-right: -32px !important;
  }

  [dir="rtl"] .m-application .me-xl-n8 {
    margin-left: -32px !important;
  }

  [dir="ltr"] .m-application .me-xl-n9 {
    margin-right: -36px !important;
  }

  [dir="rtl"] .m-application .me-xl-n9 {
    margin-left: -36px !important;
  }

  [dir="ltr"] .m-application .me-xl-n10 {
    margin-right: -40px !important;
  }

  [dir="rtl"] .m-application .me-xl-n10 {
    margin-left: -40px !important;
  }

  [dir="ltr"] .m-application .me-xl-n11 {
    margin-right: -44px !important;
  }

  [dir="rtl"] .m-application .me-xl-n11 {
    margin-left: -44px !important;
  }

  [dir="ltr"] .m-application .me-xl-n12 {
    margin-right: -48px !important;
  }

  [dir="rtl"] .m-application .me-xl-n12 {
    margin-left: -48px !important;
  }

  .m-application .pa-xl-0 {
    padding: 0px !important;
  }

  .m-application .pa-xl-1 {
    padding: 4px !important;
  }

  .m-application .pa-xl-2 {
    padding: 8px !important;
  }

  .m-application .pa-xl-3 {
    padding: 12px !important;
  }

  .m-application .pa-xl-4 {
    padding: 16px !important;
  }

  .m-application .pa-xl-5 {
    padding: 20px !important;
  }

  .m-application .pa-xl-6 {
    padding: 24px !important;
  }

  .m-application .pa-xl-7 {
    padding: 28px !important;
  }

  .m-application .pa-xl-8 {
    padding: 32px !important;
  }

  .m-application .pa-xl-9 {
    padding: 36px !important;
  }

  .m-application .pa-xl-10 {
    padding: 40px !important;
  }

  .m-application .pa-xl-11 {
    padding: 44px !important;
  }

  .m-application .pa-xl-12 {
    padding: 48px !important;
  }

  .m-application .px-xl-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .m-application .px-xl-1 {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .m-application .px-xl-2 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .m-application .px-xl-3 {
    padding-right: 12px !important;
    padding-left: 12px !important;
  }

  .m-application .px-xl-4 {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .m-application .px-xl-5 {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .m-application .px-xl-6 {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  .m-application .px-xl-7 {
    padding-right: 28px !important;
    padding-left: 28px !important;
  }

  .m-application .px-xl-8 {
    padding-right: 32px !important;
    padding-left: 32px !important;
  }

  .m-application .px-xl-9 {
    padding-right: 36px !important;
    padding-left: 36px !important;
  }

  .m-application .px-xl-10 {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .m-application .px-xl-11 {
    padding-right: 44px !important;
    padding-left: 44px !important;
  }

  .m-application .px-xl-12 {
    padding-right: 48px !important;
    padding-left: 48px !important;
  }

  .m-application .py-xl-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .m-application .py-xl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .m-application .py-xl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .m-application .py-xl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .m-application .py-xl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .m-application .py-xl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .m-application .py-xl-6 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .m-application .py-xl-7 {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }

  .m-application .py-xl-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .m-application .py-xl-9 {
    padding-top: 36px !important;
    padding-bottom: 36px !important;
  }

  .m-application .py-xl-10 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .m-application .py-xl-11 {
    padding-top: 44px !important;
    padding-bottom: 44px !important;
  }

  .m-application .py-xl-12 {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  .m-application .pt-xl-0 {
    padding-top: 0px !important;
  }

  .m-application .pt-xl-1 {
    padding-top: 4px !important;
  }

  .m-application .pt-xl-2 {
    padding-top: 8px !important;
  }

  .m-application .pt-xl-3 {
    padding-top: 12px !important;
  }

  .m-application .pt-xl-4 {
    padding-top: 16px !important;
  }

  .m-application .pt-xl-5 {
    padding-top: 20px !important;
  }

  .m-application .pt-xl-6 {
    padding-top: 24px !important;
  }

  .m-application .pt-xl-7 {
    padding-top: 28px !important;
  }

  .m-application .pt-xl-8 {
    padding-top: 32px !important;
  }

  .m-application .pt-xl-9 {
    padding-top: 36px !important;
  }

  .m-application .pt-xl-10 {
    padding-top: 40px !important;
  }

  .m-application .pt-xl-11 {
    padding-top: 44px !important;
  }

  .m-application .pt-xl-12 {
    padding-top: 48px !important;
  }

  .m-application .pr-xl-0 {
    padding-right: 0px !important;
  }

  .m-application .pr-xl-1 {
    padding-right: 4px !important;
  }

  .m-application .pr-xl-2 {
    padding-right: 8px !important;
  }

  .m-application .pr-xl-3 {
    padding-right: 12px !important;
  }

  .m-application .pr-xl-4 {
    padding-right: 16px !important;
  }

  .m-application .pr-xl-5 {
    padding-right: 20px !important;
  }

  .m-application .pr-xl-6 {
    padding-right: 24px !important;
  }

  .m-application .pr-xl-7 {
    padding-right: 28px !important;
  }

  .m-application .pr-xl-8 {
    padding-right: 32px !important;
  }

  .m-application .pr-xl-9 {
    padding-right: 36px !important;
  }

  .m-application .pr-xl-10 {
    padding-right: 40px !important;
  }

  .m-application .pr-xl-11 {
    padding-right: 44px !important;
  }

  .m-application .pr-xl-12 {
    padding-right: 48px !important;
  }

  .m-application .pb-xl-0 {
    padding-bottom: 0px !important;
  }

  .m-application .pb-xl-1 {
    padding-bottom: 4px !important;
  }

  .m-application .pb-xl-2 {
    padding-bottom: 8px !important;
  }

  .m-application .pb-xl-3 {
    padding-bottom: 12px !important;
  }

  .m-application .pb-xl-4 {
    padding-bottom: 16px !important;
  }

  .m-application .pb-xl-5 {
    padding-bottom: 20px !important;
  }

  .m-application .pb-xl-6 {
    padding-bottom: 24px !important;
  }

  .m-application .pb-xl-7 {
    padding-bottom: 28px !important;
  }

  .m-application .pb-xl-8 {
    padding-bottom: 32px !important;
  }

  .m-application .pb-xl-9 {
    padding-bottom: 36px !important;
  }

  .m-application .pb-xl-10 {
    padding-bottom: 40px !important;
  }

  .m-application .pb-xl-11 {
    padding-bottom: 44px !important;
  }

  .m-application .pb-xl-12 {
    padding-bottom: 48px !important;
  }

  .m-application .pl-xl-0 {
    padding-left: 0px !important;
  }

  .m-application .pl-xl-1 {
    padding-left: 4px !important;
  }

  .m-application .pl-xl-2 {
    padding-left: 8px !important;
  }

  .m-application .pl-xl-3 {
    padding-left: 12px !important;
  }

  .m-application .pl-xl-4 {
    padding-left: 16px !important;
  }

  .m-application .pl-xl-5 {
    padding-left: 20px !important;
  }

  .m-application .pl-xl-6 {
    padding-left: 24px !important;
  }

  .m-application .pl-xl-7 {
    padding-left: 28px !important;
  }

  .m-application .pl-xl-8 {
    padding-left: 32px !important;
  }

  .m-application .pl-xl-9 {
    padding-left: 36px !important;
  }

  .m-application .pl-xl-10 {
    padding-left: 40px !important;
  }

  .m-application .pl-xl-11 {
    padding-left: 44px !important;
  }

  .m-application .pl-xl-12 {
    padding-left: 48px !important;
  }

  [dir="ltr"] .m-application .ps-xl-0 {
    padding-left: 0px !important;
  }

  [dir="rtl"] .m-application .ps-xl-0 {
    padding-right: 0px !important;
  }

  [dir="ltr"] .m-application .ps-xl-1 {
    padding-left: 4px !important;
  }

  [dir="rtl"] .m-application .ps-xl-1 {
    padding-right: 4px !important;
  }

  [dir="ltr"] .m-application .ps-xl-2 {
    padding-left: 8px !important;
  }

  [dir="rtl"] .m-application .ps-xl-2 {
    padding-right: 8px !important;
  }

  [dir="ltr"] .m-application .ps-xl-3 {
    padding-left: 12px !important;
  }

  [dir="rtl"] .m-application .ps-xl-3 {
    padding-right: 12px !important;
  }

  [dir="ltr"] .m-application .ps-xl-4 {
    padding-left: 16px !important;
  }

  [dir="rtl"] .m-application .ps-xl-4 {
    padding-right: 16px !important;
  }

  [dir="ltr"] .m-application .ps-xl-5 {
    padding-left: 20px !important;
  }

  [dir="rtl"] .m-application .ps-xl-5 {
    padding-right: 20px !important;
  }

  [dir="ltr"] .m-application .ps-xl-6 {
    padding-left: 24px !important;
  }

  [dir="rtl"] .m-application .ps-xl-6 {
    padding-right: 24px !important;
  }

  [dir="ltr"] .m-application .ps-xl-7 {
    padding-left: 28px !important;
  }

  [dir="rtl"] .m-application .ps-xl-7 {
    padding-right: 28px !important;
  }

  [dir="ltr"] .m-application .ps-xl-8 {
    padding-left: 32px !important;
  }

  [dir="rtl"] .m-application .ps-xl-8 {
    padding-right: 32px !important;
  }

  [dir="ltr"] .m-application .ps-xl-9 {
    padding-left: 36px !important;
  }

  [dir="rtl"] .m-application .ps-xl-9 {
    padding-right: 36px !important;
  }

  [dir="ltr"] .m-application .ps-xl-10 {
    padding-left: 40px !important;
  }

  [dir="rtl"] .m-application .ps-xl-10 {
    padding-right: 40px !important;
  }

  [dir="ltr"] .m-application .ps-xl-11 {
    padding-left: 44px !important;
  }

  [dir="rtl"] .m-application .ps-xl-11 {
    padding-right: 44px !important;
  }

  [dir="ltr"] .m-application .ps-xl-12 {
    padding-left: 48px !important;
  }

  [dir="rtl"] .m-application .ps-xl-12 {
    padding-right: 48px !important;
  }

  [dir="ltr"] .m-application .pe-xl-0 {
    padding-right: 0px !important;
  }

  [dir="rtl"] .m-application .pe-xl-0 {
    padding-left: 0px !important;
  }

  [dir="ltr"] .m-application .pe-xl-1 {
    padding-right: 4px !important;
  }

  [dir="rtl"] .m-application .pe-xl-1 {
    padding-left: 4px !important;
  }

  [dir="ltr"] .m-application .pe-xl-2 {
    padding-right: 8px !important;
  }

  [dir="rtl"] .m-application .pe-xl-2 {
    padding-left: 8px !important;
  }

  [dir="ltr"] .m-application .pe-xl-3 {
    padding-right: 12px !important;
  }

  [dir="rtl"] .m-application .pe-xl-3 {
    padding-left: 12px !important;
  }

  [dir="ltr"] .m-application .pe-xl-4 {
    padding-right: 16px !important;
  }

  [dir="rtl"] .m-application .pe-xl-4 {
    padding-left: 16px !important;
  }

  [dir="ltr"] .m-application .pe-xl-5 {
    padding-right: 20px !important;
  }

  [dir="rtl"] .m-application .pe-xl-5 {
    padding-left: 20px !important;
  }

  [dir="ltr"] .m-application .pe-xl-6 {
    padding-right: 24px !important;
  }

  [dir="rtl"] .m-application .pe-xl-6 {
    padding-left: 24px !important;
  }

  [dir="ltr"] .m-application .pe-xl-7 {
    padding-right: 28px !important;
  }

  [dir="rtl"] .m-application .pe-xl-7 {
    padding-left: 28px !important;
  }

  [dir="ltr"] .m-application .pe-xl-8 {
    padding-right: 32px !important;
  }

  [dir="rtl"] .m-application .pe-xl-8 {
    padding-left: 32px !important;
  }

  [dir="ltr"] .m-application .pe-xl-9 {
    padding-right: 36px !important;
  }

  [dir="rtl"] .m-application .pe-xl-9 {
    padding-left: 36px !important;
  }

  [dir="ltr"] .m-application .pe-xl-10 {
    padding-right: 40px !important;
  }

  [dir="rtl"] .m-application .pe-xl-10 {
    padding-left: 40px !important;
  }

  [dir="ltr"] .m-application .pe-xl-11 {
    padding-right: 44px !important;
  }

  [dir="rtl"] .m-application .pe-xl-11 {
    padding-left: 44px !important;
  }

  [dir="ltr"] .m-application .pe-xl-12 {
    padding-right: 48px !important;
  }

  [dir="rtl"] .m-application .pe-xl-12 {
    padding-left: 48px !important;
  }

  .m-application .text-xl-left {
    text-align: left !important;
  }

  .m-application .text-xl-right {
    text-align: right !important;
  }

  .m-application .text-xl-center {
    text-align: center !important;
  }

  .m-application .text-xl-justify {
    text-align: justify !important;
  }

  .m-application .text-xl-start {
    text-align: start !important;
  }

  .m-application .text-xl-end {
    text-align: end !important;
  }
}

