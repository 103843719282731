#payment-step {
  .root {
    text-align: center;
  }

  .deco-bg {
    position: absolute;
    left: 0;
    top: 275px;
    max-width: 950px;
    width: 100%;
    height: 642px;
    @include breakpoints-down(xs) {
      display: none;
    }
    svg {
      width: 100%
    }
  }

  .primary-light {
    fill: $palette-primary-light
  }

  .primary-main {
    fill: $palette-primary-main
  }

  .primary-dark {
    fill: $palette-primary-dark
  }

  .secondary-main {
    fill: $palette-secondary-main
  }

  .secondary-dark {
    fill: $palette-secondary-dark
  }

  .accent-main {
    fill: $palette-accent-main
  }

  .wrapper {
    margin: 0 auto;
    position: relative;
    @include breakpoints-up(sm) {
      padding-top: $spacing5;
    }
    @include breakpoints-up(lg) {
      padding-top: $spacing10;
    }
  }

  .carousel {
    margin: $spacing8 auto -80px;
    @include breakpoints-up(md) {
      padding-left: $spacing10;
      padding-right: $spacing10;
    }
    figure {
      border-radius: 10px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    & > .slider {
      height: auto;
      display: flex;
      flex-direction: column-reverse;
    }
    .slick-dots{
      position: relative;
      bottom: $spacing3;
      li {
        width: 15px;
        height: 15px;
        border: 2px solid $palette-primary-main;
        border-radius: 15px;
        opacity: 0.7;
        transition: all 0.5s ease-out;
        margin: 0 4px;
        &.slick-active {
          background: $palette-primary-main;
        }
      }
      li button:before {
        display: none
      }
    }
  }

  .slide {
    @include breakpoints-up(sm) {
      padding: spacing(0.5, 1);
    }
  }

  .paper {
    @include shade2;
    border-radius: $rounded-big;
    margin: $spacing1;
    overflow: hidden;
    position: relative;
  }

  .desc {
    padding: spacing(0, 0, 8, 3);
    @include breakpoints-down(xs) {
      padding: spacing(0, 0, 8, 0);
      button {
        width: 100%
      }
    }
  }

  .img {
    margin: spacing(3, 0, 0, 0);
    overflow: hidden;
    @include breakpoints-down(md) {
      @include text-align(right);
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%
    }
    img {
      max-width: 100%;
      display: block;
      @include breakpoints-down(sm) {
        width: 100%
      }
    }
  }
}
